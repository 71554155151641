import { Popover } from "@mui/material";
import MultiSort from "../multiSort";
import { HiDotsVertical } from "react-icons/hi";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa6";
import { FaFilter, FaSortAmountDown } from "react-icons/fa";
import MenuComponent from "components/common/Menu";
import { useRef, useState } from "react";
import { TableColumn } from "../type";
import { useDrag, useDrop } from "react-dnd";
import styles from "./styles.module.css";
import MultiFilter from "../multiFilter";

const DraggableHeader: React.FC<{
  allColumns: TableColumn[];
  column: TableColumn;
  index: number;
  filterable: boolean;
  sortKey: string | null;
  sortOrder: "asc" | "desc" | null;
  sortModel: {
    sortKey: string;
    sortOrder: "asc" | "desc";
  }[];

  filterModel: {
    filterKey: string;
    filterValues: string[] | number[];
  }[];

  onSort: (key: string) => void;
  onMultiSort: (
    key: {
      sortKey: string;
      sortOrder: "asc" | "desc";
    }[]
  ) => void;
  setSortModel: React.Dispatch<
    React.SetStateAction<
      {
        sortKey: string;
        sortOrder: "asc" | "desc";
      }[]
    >
  >;

  onMultiFilter: (
    key: {
      filterKey: string;
      filterValues: string[] | number[];
    }[]
  ) => void;
  setFilterModel: React.Dispatch<
    React.SetStateAction<
      {
        filterKey: string;
        filterValues: string[] | number[];
      }[]
    >
  >;
  moveColumn: (dragIndex: number, hoverIndex: number) => void;
}> = ({
  allColumns,
  column,
  index,
  sortKey,
  sortOrder,
  sortModel,
  onSort,
  onMultiSort,
  setSortModel,
  filterModel,
  onMultiFilter,
  setFilterModel,
  filterable,
  moveColumn,
}) => {
  const [anchorElSort, setAnchorElSort] = useState<HTMLButtonElement | null>(
    null
  );
  const sortMenuRef: any = useRef(null);
  const [anchorElFilter, setAnchorElFilter] =
    useState<HTMLButtonElement | null>(null);
  const filterMenuRef: any = useRef(null);

  const [, ref] = useDrag({
    type: "COLUMN",
    item: { index },
  });

  const [, drop] = useDrop({
    accept: "COLUMN",
    hover: (item: { index: number }) => {
      if (item.index !== index) {
        moveColumn(item.index, index);
        item.index = index;
      }
    },
  });

  const handleSort = () => {
    if (column.sortable) {
      onSort(column.key);
      setSortModel([]);
    }
  };

  const handleClickSortMenu = () => {
    let element: any = document.getElementById(column.key);
    setAnchorElSort(element);
  };
  const handleCloseSortMenu = () => {
    setAnchorElSort(null);
  };
  const openSortMenu = Boolean(anchorElSort);

  const handleClickFilterMenu = () => {
    let element: any = document.getElementById(column.key);
    setAnchorElFilter(element);
  };
  const handleCloseFilterMenu = () => {
    setAnchorElFilter(null);
  };
  const openFilterMenu = Boolean(anchorElFilter);

  return (
    <th
      ref={(node) => ref(drop(node))}
      className={styles.tableHeaderCell}
      style={{ width: column.width ?? 200 }}
      id={column.key}
    >
      {column.title}
      {column.sortable && (
        <>
          {sortKey === column.key ? (
            sortOrder === "asc" ? (
              <FaSortUp
                onClick={handleSort}
                className={styles.headerCellIcons}
              />
            ) : (
              <FaSortDown
                onClick={handleSort}
                className={styles.headerCellIcons}
              />
            )
          ) : (
            <FaSort onClick={handleSort} className={styles.headerCellIcons} />
          )}
        </>
      )}
      {filterModel.some(
        (model) =>
          model.filterKey === column.key && model.filterValues.length > 0
      ) && <FaFilter />}
      <>
        <MenuComponent
          options={[
            {
              title: (
                <>
                  <FaSortAmountDown />
                  <span style={{ marginLeft: 12 }}>Multi Sort</span>
                </>
              ),
              handleClick: () => {
                handleClickSortMenu();
                if (
                  sortModel.length < 1 ||
                  sortModel.every((sm) => sm.sortKey !== column.key)
                ) {
                  setSortModel((prevState) => [
                    ...prevState,
                    { sortKey: column.key, sortOrder: "asc" },
                  ]);
                }
              },
            },
            ...(filterable
              ? [
                  {
                    title: (
                      <>
                        <FaFilter />
                        <span style={{ marginLeft: 12 }}>Filter</span>
                      </>
                    ),
                    handleClick: () => {
                      handleClickFilterMenu();
                      if (
                        filterModel.length < 1 ||
                        filterModel.every((sm) => sm.filterKey !== column.key)
                      ) {
                        setFilterModel((prevState) => [
                          ...prevState,
                          { filterKey: column.key, filterValues: [] },
                        ]);
                      }
                    },
                  },
                ]
              : []),
          ]}
        >
          <HiDotsVertical className={styles.headerCellIcons} />
        </MenuComponent>
        <div>
          <Popover
            id={openSortMenu ? "simple-popover" : undefined}
            open={openSortMenu}
            anchorEl={anchorElSort}
            onClose={handleCloseSortMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            sx={{ borderRadius: 10 }}
          >
            <MultiSort
              ref={sortMenuRef}
              column={column}
              allColumns={allColumns}
              sortModel={sortModel}
              setSortModel={setSortModel}
              handleMultiSort={() => onMultiSort(sortModel)}
              handleClose={handleCloseSortMenu}
            />
          </Popover>
        </div>
        {filterable && (
          <div>
            <Popover
              id={openFilterMenu ? "simple-popover" : undefined}
              open={openFilterMenu}
              anchorEl={anchorElFilter}
              onClose={handleCloseFilterMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              sx={{ borderRadius: 10 }}
            >
              <MultiFilter
                ref={filterMenuRef}
                column={column}
                allColumns={allColumns}
                filterModel={filterModel}
                setFilterModel={setFilterModel}
                handleMultiFilter={() => onMultiFilter(filterModel)}
                handleClose={handleCloseFilterMenu}
              />
            </Popover>
          </div>
        )}
      </>
    </th>
  );
};

export default DraggableHeader;
