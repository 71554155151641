import { IFilterContext, IOptions } from "types/type";
import React, { ReactNode, useState } from "react";

type Props = {
  children: ReactNode;
};

interface ContextState {
  filterList: IFilterContext;
  prevFilterList: any;
  setFilterList: React.Dispatch<React.SetStateAction<IFilterContext>>;
  setPrevFilterList: React.Dispatch<React.SetStateAction<any>>;
  options: IOptions | null;
  setOptions: React.Dispatch<React.SetStateAction<IOptions | null>>;
}

const contextDefaultValues: ContextState = {
  filterList: {
    origRegion: [],
    destRegion: [],
    origCountry: [],
    destCountry: [],
    origCityAirport: [],
    destCityAirport: [],
    pos: [],
    mainCompetitor: [],
    selectedCompetitors: [],
    salesChannel: [],
    cabin: [],
    paxType: [],
    currency: [],
  },
  setFilterList: () => {},
  prevFilterList: {},
  setPrevFilterList: () => {},
  options: null,
  setOptions: () => {},
};
const FilterContext = React.createContext<ContextState>(contextDefaultValues);
const FilterConsumer = FilterContext.Consumer;

const FilterContextApp = ({ children }: Props) => {
  const [filterList, setFilterList] = useState<IFilterContext>({
    origRegion: [],
    destRegion: [],
    origCountry: [],
    destCountry: [],
    origCityAirport: [],
    destCityAirport: [],
    pos: [],
    mainCompetitor: [],
    selectedCompetitors: [],
    salesChannel: [],
    cabin: [],
    paxType: [],
    currency: [],
  });
  const [prevFilterList, setPrevFilterList] = useState<any>({});
  const [options, setOptions] = useState<IOptions | null>(null);

  return (
    <FilterContext.Provider
      value={{
        filterList,
        setFilterList,
        prevFilterList,
        setPrevFilterList,
        options,
        setOptions,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export { FilterContext, FilterConsumer, FilterContextApp };
