import BOOKINGGROWTH from "modules/clevel/booking-growth";


const FA = () => {
  return (
    <div>
      <BOOKINGGROWTH />
    </div>
  );
};

export default FA;
