import { IAvaibilityTrendsFilterContext } from "types/type";
import moment from "moment";
import React, { ReactNode, useState } from "react";

type Props = {
  children: ReactNode;
};

interface ContextState {
  avaibilityTrendsFilterList: IAvaibilityTrendsFilterContext;
  setAvaibilityTrendsFilterList: any;
  maxDate: null | Date;
  setMaxDate: any;
}

const contextDefaultValues: ContextState = {
  avaibilityTrendsFilterList: {
    date_range_start: moment(new Date()).format("YYYY-MM-DD"),
    date_range_end: moment(new Date()).add(100, "d").format("YYYY-MM-DD"),
    time_range_start: "00:00",
    time_range_end: "23:59",
    duration: "",
    weekdays: [],
    connection: ["0", "1"],
  },
  setAvaibilityTrendsFilterList: () => {},
  maxDate: null,
  setMaxDate: () => {},
};
const AvaibilityTrendsFilterContext =
  React.createContext<ContextState>(contextDefaultValues);
const AvaibilityTrendsFilterConsumer = AvaibilityTrendsFilterContext.Consumer;

const AvaibilityTrendsFilterContextApp = ({ children }: Props) => {
  const [avaibilityTrendsFilterList, setAvaibilityTrendsFilterList] = useState({
    date_range_start: moment(new Date()).format("YYYY-MM-DD"),
    date_range_end: moment(new Date()).add(100, "d").format("YYYY-MM-DD"),
    time_range_start: "00:00",
    time_range_end: "23:59",
    duration: "0",
    weekdays: [],
    connection: ["0", "1"],
  });
  const [maxDate, setMaxDate] = useState(null);
  return (
    <AvaibilityTrendsFilterContext.Provider
      value={{
        avaibilityTrendsFilterList,
        setAvaibilityTrendsFilterList,
        maxDate,
        setMaxDate,
      }}
    >
      {children}
    </AvaibilityTrendsFilterContext.Provider>
  );
};

export {
  AvaibilityTrendsFilterContext,
  AvaibilityTrendsFilterConsumer,
  AvaibilityTrendsFilterContextApp,
};
