import { RestClient } from "../../RestClient";

export class ClevelHeatmapClient {
  private static _client: RestClient;
  static fetchClevelHeatmapClient(params) {
    return ClevelHeatmapClient.getClient().getCall(
      `/api/msdv2/c-level/heatmap?${new URLSearchParams(params)}`
    );
  }

  static getClient() {
    if (ClevelHeatmapClient._client == null) {
      ClevelHeatmapClient._client = new RestClient();
    }
    return ClevelHeatmapClient._client;
  }
}
