import { IFilterContext } from "types/type";
import { QueryParameterStringBuilder } from "lib/query-parameter-string-builder";
import { RestClient } from "../../RestClient";

interface IFareRevenueClassMix {
  filterList: IFilterContext;
  selected_yearmonth?: string;
  dark_theme: boolean;
}

export class FareRevenueClassMixClient {
  private static _client: RestClient;

  static fetchFareRevenueClassMix({
    filterList,
    selected_yearmonth,
    dark_theme,
  }: IFareRevenueClassMix) {
    const qp = QueryParameterStringBuilder.apply(filterList);
    return FareRevenueClassMixClient.getClient().getCall(
      `/api/msdv2/fare-revenue/class-mix?selected_yearmonth=${selected_yearmonth}&${qp}&dark_theme=${dark_theme}`
    );
  }

  static getClient() {
    if (FareRevenueClassMixClient._client == null) {
      FareRevenueClassMixClient._client = new RestClient();
    }
    return FareRevenueClassMixClient._client;
  }
}
