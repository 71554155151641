import Card from "components/common/Card/Card";
import styles from "./styles.module.css";
import images from "constans/images";
import { useContext } from "react";
import { ThemeContext } from "context-api/ThemeContext";
import Skeleton from "components/common/Skeleton";

interface IFareImprovements {
  data: string;
  skeletonLoader: boolean;
}
export default function FareImprovements({
  data,
  skeletonLoader,
}: IFareImprovements) {
  const { theme } = useContext(ThemeContext);
  return (
    <Card
      variant="secondary"
      width={"100%"}
      height={144}
      borderRadius="15px 15px 50px 15px"
      borderColor="#ff8f1f"
    >
      {skeletonLoader ? (
        <Skeleton width={"100%"} height={128} />
      ) : (
        <div className={styles.container} data-theme={theme}>
          <span className={styles.title}>
            Revenue Opportunity from Fare Improvements
          </span>
          <p className={styles.value}>{data}</p>
          <span className={styles.icon_wrapper}>
            <img src={images.money_coint_growth} alt="" />
          </span>
        </div>
      )}
    </Card>
  );
}
