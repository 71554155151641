import React, { ReactNode, useState } from "react";

type Props = {
  children: ReactNode;
};
interface IContext {
  owRtSwitch: "rt" | "ow" | null;
  setOwRtSwitch: React.Dispatch<React.SetStateAction<"rt" | "ow" | null>>;
  flightTypeSwitch: boolean;
  setFlightTypeSwitch: React.Dispatch<React.SetStateAction<boolean>>;
  allFlightType: "ow" | "rt" | null;
  setAllFlightType: React.Dispatch<React.SetStateAction<"ow" | "rt" | null>>;
  cs: boolean;
  setCs: React.Dispatch<React.SetStateAction<boolean>>;
}
const contextDefaultValues: IContext = {
  owRtSwitch: null,
  setOwRtSwitch: () => {},
  flightTypeSwitch: false,
  setFlightTypeSwitch: () => {},
  allFlightType: null,
  setAllFlightType: () => {},
  cs: false,
  setCs: () => {},
};
const FlightTypeSwitchContext =
  React.createContext<IContext>(contextDefaultValues);
const FlightTypeSwitchConsumer = FlightTypeSwitchContext.Consumer;

const FlightTypeSwitchApp = ({ children }: Props) => {
  const [flightTypeSwitch, setFlightTypeSwitch] = useState(false);
  const [allFlightType, setAllFlightType] = useState<"ow" | "rt" | null>(null);
  const [owRtSwitch, setOwRtSwitch] = useState<"ow" | "rt" | null>(null);
  const [cs, setCs] = useState<boolean>(false);

  return (
    <FlightTypeSwitchContext.Provider
      value={{
        owRtSwitch,
        setOwRtSwitch,
        flightTypeSwitch,
        setFlightTypeSwitch,
        allFlightType,
        setAllFlightType,
        cs,
        setCs,
      }}
    >
      {children}
    </FlightTypeSwitchContext.Provider>
  );
};

export {
  FlightTypeSwitchContext,
  FlightTypeSwitchConsumer,
  FlightTypeSwitchApp,
};
