import { TableColumn } from "components/common/Table/newTable/type";

interface ILabels {
  label: string;
  column: string;
  options: { label: string; value: string }[] | null;
  range: string[] | number[] | null;
  type: "text" | "date" | "number" | "select" | "time";
}

const generateTableColumns = (labels: ILabels[]): TableColumn[] => {
  return labels.map((element) => ({
    title: element.label,
    key: element.column,
    cell: (row) => row[element.column],
    width: 200,
    sortable: true,
    options: element.options,
    range: element.range,
    type: element.type ?? "text",
  }));
};

export default generateTableColumns;
