/* eslint-disable react-hooks/exhaustive-deps */
import Card from "../Card/Card";
import styles from "./MainFilterOpened.module.css";
import Dropdown from "../Dropdown/Dropdown";
import DropdownCityAirport from "../Dropdown/DropdownCityAirport";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import Button from "../Button/Button";
import { FilterContext } from "context-api/FilterContext";
import { FilterClient } from "lib/api/filter";
import { ThemeContext } from "context-api/ThemeContext";
import { FlightTypeSwitchContext } from "context-api/OwRtContext";
import CompareFilterObjects from "utils/CompareFilterObjects";
import images from "constans/images";

interface IMainFilterOpened {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}
export default function MainFilterOpened({ setIsOpen }: IMainFilterOpened) {
  const [origCityAirportLookup, setOrigCityAirportLookup] = useState("");
  const [destCityAirportLookup, setDestCityAirportLookup] = useState("");
  const [getCarriers, setGetCarriers] = useState<any>();
  const { theme } = useContext(ThemeContext);
  const {
    filterList,
    setFilterList,
    prevFilterList,
    setPrevFilterList,
    options,
    setOptions,
  } = useContext(FilterContext);
  const { setFlightTypeSwitch, setAllFlightType, setOwRtSwitch, owRtSwitch } =
    useContext(FlightTypeSwitchContext);
  const module = localStorage.getItem("module");

  const handleAcceptFilter = () => {
    setIsOpen(false);
    let user = localStorage.getItem("user");
    if (user) {
      let parsedUserData = {
        ...JSON.parse(user),
        defaultFilterSelection: filterList,
      };
      localStorage.setItem("user", JSON.stringify(parsedUserData));
    }
  };

  const clearFilter = () => {
    let empty = filterList;
    Object.keys(empty).forEach((key) => {
      empty[key] = [];
    });
    setFilterList((prevState) => ({ ...prevState, empty }));
  };

  useEffect(() => {
    const filterParams = {
      ...filterList,
      orig_city_airport_lookup: origCityAirportLookup.toUpperCase(),
      dest_city_airport_lookup: destCityAirportLookup.toUpperCase(),
    };
    if (!CompareFilterObjects(prevFilterList, filterParams)) {
      setPrevFilterList(filterParams);
      getDropdown(filterParams);
    }
  }, [
    filterList,
    prevFilterList,
    origCityAirportLookup,
    destCityAirportLookup,
  ]);

  const getDropdown = (filterParams) => {
    FilterClient.fetchFilterOptions(filterParams)
      .then((result: any) => {
        setOptions(result);
        localStorage.setItem(
          "allCompetitors",
          JSON.stringify(result?.main_competitor)
        );
        setGetCarriers(result?.carriers);
        setFlightTypeSwitch(result?.flight_type_switch);
        setAllFlightType(result?.all_flight_type?.toLowerCase() ?? null);
        setOwRtSwitch(
          result?.all_flight_type?.toLowerCase() ?? owRtSwitch ?? "ow"
        );
      })
      .catch(() => {
        console.log("Error");
      });
  };

  const arraySorting = (array: any[]) => {
    const sortedArray = array?.sort((a, b) => {
      if (a.code < b.code) {
        return -1;
      }
      if (a.code > b.code) {
        return 1;
      }
      return 0;
    });
    return sortedArray;
  };
  const HandleFilterListSelectedChange = ({ param, item }) => {
    if (item[item.length - 1] === "All") {
      setFilterList((prevState) => ({ ...prevState, [param]: ["All"] }));
    } else {
      const other = item.filter((element) => element !== "All");
      setFilterList((prevState) => ({ ...prevState, [param]: other }));
    }
  };

  const handleChangeFromTo = (data) => {
    setFilterList((prevState) => ({
      ...prevState,
      [data.from]: data.toData,
    }));
    setFilterList((prevState) => ({
      ...prevState,
      [data.to]: data.fromData,
    }));
  };

  return (
    <div className={styles.container}>
      <Card variant="secondary" zIndex={3}>
        <div className={styles.head}>
          <span className={styles.edit_filter_text} data-theme={theme}>
            Edit Filters
          </span>
          <div className={styles.head_buttons_wrapper}>
            <div
              className={styles.clear_button}
              data-theme={theme}
              onClick={() => clearFilter()}
            >
              Clear
            </div>
            <div
              className={styles.tick_button_wrapper}
              data-theme={theme}
              onClick={() => {
                if (filterList.mainCompetitor.length > 0) {
                  handleAcceptFilter();
                }
              }}
            >
              <img
                src={
                  filterList.mainCompetitor.length > 0
                    ? images.tick_green
                    : images.cancel_red
                }
                alt=""
              />
            </div>
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.card_wrapper}>
            <Card
              variant="primary"
              title="Region"
              fontSize={16}
              icon={images.navigation_maps}
              iconSize="sm"
              // width={252}
              height={252}
              zIndex={10}
            >
              <div className={styles.filter_card}>
                <p className={styles.from_text} data-theme={theme}>
                  From:
                </p>
                <Dropdown
                  data={options?.orig_region ?? []}
                  added={filterList.origRegion}
                  setAdded={(item) => {
                    HandleFilterListSelectedChange({
                      param: "origRegion",
                      item,
                    });
                  }}
                  disabled={
                    filterList.origCountry.length > 0 ||
                    filterList.origCityAirport.length > 0
                  }
                  getCarriers={getCarriers}
                  singleSelected={module === "LFA"}
                />
                <div
                  className={styles.change_from_to_arrow_wrapper}
                  onClick={() =>
                    handleChangeFromTo({
                      from: "origRegion",
                      fromData: filterList.origRegion,
                      to: "destRegion",
                      toData: filterList.destRegion,
                    })
                  }
                >
                  <img
                    src={images.arrow_change}
                    alt=""
                    width={24}
                    height={24}
                  />
                </div>
                <p className={styles.to_text} data-theme={theme}>
                  To:
                </p>
                <Dropdown
                  data={options?.dest_region ?? []}
                  added={filterList.destRegion}
                  setAdded={(item) => {
                    HandleFilterListSelectedChange({
                      param: "destRegion",
                      item,
                    });
                  }}
                  disabled={
                    (filterList.origRegion.length < 1 &&
                      filterList.origCountry.length < 1 &&
                      filterList.origCityAirport.length < 1) ||
                    filterList.destCityAirport.length > 0 ||
                    filterList.destCountry.length > 0
                  }
                  getCarriers={getCarriers}
                  singleSelected={module === "LFA"}
                />
              </div>
            </Card>
          </div>
          <div className={styles.card_wrapper}>
            <Card
              variant="primary"
              title="Country"
              fontSize={16}
              icon={images.flag}
              iconSize="sm"
              // width={398}
              height={252}
              zIndex={9}
            >
              <div className={styles.filter_card}>
                <p className={styles.from_text} data-theme={theme}>
                  From:
                </p>
                <Dropdown
                  data={options?.orig_country ?? []}
                  added={filterList.origCountry}
                  setAdded={(item) => {
                    HandleFilterListSelectedChange({
                      param: "origCountry",
                      item,
                    });
                  }}
                  disabled={
                    filterList.origRegion.length > 0 ||
                    filterList.origCityAirport.length > 0
                  }
                  getCarriers={getCarriers}
                  singleSelected={module === "LFA"}
                />
                <div
                  className={styles.change_from_to_arrow_wrapper}
                  onClick={() =>
                    handleChangeFromTo({
                      from: "origCountry",
                      fromData: filterList.origCountry,
                      to: "destCountry",
                      toData: filterList.destCountry,
                    })
                  }
                >
                  <img
                    src={images.arrow_change}
                    alt=""
                    width={24}
                    height={24}
                  />
                </div>
                <p className={styles.to_text} data-theme={theme}>
                  To:
                </p>
                <Dropdown
                  data={options?.dest_country ?? []}
                  added={filterList.destCountry}
                  setAdded={(item) => {
                    HandleFilterListSelectedChange({
                      param: "destCountry",
                      item,
                    });
                  }}
                  disabled={
                    (filterList.origRegion.length < 1 &&
                      filterList.origCountry.length < 1 &&
                      filterList.origCityAirport.length < 1) ||
                    filterList.destRegion.length > 0 ||
                    filterList.destCityAirport.length > 0
                  }
                  getCarriers={getCarriers}
                  singleSelected={module === "LFA"}
                />
              </div>
            </Card>
          </div>
          <div className={styles.card_wrapper}>
            <Card
              variant="primary"
              title="City / Airport"
              fontSize={16}
              icon={images.travel_plane_take_off}
              iconSize="sm"
              // width={398}
              height={252}
              zIndex={8}
            >
              <div className={styles.filter_card}>
                <p className={styles.from_text} data-theme={theme}>
                  From:
                </p>
                <DropdownCityAirport
                  data={arraySorting(options?.orig_city_airport ?? [])}
                  added={filterList.origCityAirport}
                  setAdded={(item) => {
                    HandleFilterListSelectedChange({
                      param: "origCityAirport",
                      item,
                    });
                  }}
                  disabled={
                    filterList.origRegion.length > 0 ||
                    filterList.origCountry.length > 0
                  }
                  getCarriers={getCarriers}
                  singleSelected={module === "LFA"}
                  setSearchFilter={(search) => setOrigCityAirportLookup(search)}
                />
                <div
                  className={styles.change_from_to_arrow_wrapper}
                  onClick={() =>
                    handleChangeFromTo({
                      from: "origCityAirport",
                      fromData: filterList.origCityAirport,
                      to: "destCityAirport",
                      toData: filterList.destCityAirport,
                    })
                  }
                >
                  <img
                    src={images.arrow_change}
                    alt=""
                    width={24}
                    height={24}
                  />
                </div>
                <p className={styles.to_text} data-theme={theme}>
                  To:
                </p>
                <DropdownCityAirport
                  data={arraySorting(options?.dest_city_airport ?? [])}
                  added={filterList.destCityAirport}
                  setAdded={(item) => {
                    HandleFilterListSelectedChange({
                      param: "destCityAirport",
                      item,
                    });
                  }}
                  disabled={
                    (filterList.origRegion.length < 1 &&
                      filterList.origCountry.length < 1 &&
                      filterList.origCityAirport.length < 1) ||
                    filterList.destRegion.length > 0 ||
                    filterList.destCountry.length > 0
                  }
                  getCarriers={getCarriers}
                  singleSelected={module === "LFA"}
                  setSearchFilter={(search) => setDestCityAirportLookup(search)}
                />
              </div>
            </Card>
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.pos_comp_card_wrapper}>
            <Card
              variant="primary"
              title="Point of Sale"
              fontSize={16}
              titleMargin="24px 16px 20px 16px"
              icon={images.travel_map_point}
              iconSize="sm"
              zIndex={7}
            >
              <div className={styles.filter_card}>
                <Dropdown
                  data={options?.pos ?? []}
                  added={filterList.pos}
                  setAdded={(item) => {
                    if (
                      filterList.destRegion.length > 0 ||
                      filterList.destCountry.length > 0 ||
                      (filterList.destCityAirport.length > 0 &&
                        item.length === 0)
                    ) {
                      HandleFilterListSelectedChange({
                        param: "pos",
                        item: ["All"],
                      });
                      return;
                    }
                    HandleFilterListSelectedChange({
                      param: "pos",
                      item,
                    });
                  }}
                  disabled={
                    filterList.destRegion.length < 1 &&
                    filterList.destCountry.length < 1 &&
                    filterList.destCityAirport.length < 1
                  }
                  getCarriers={getCarriers}
                />
              </div>
            </Card>
          </div>
          <div className={styles.pos_comp_card_wrapper}>
            <Card
              variant="primary"
              title="Currencies"
              fontSize={16}
              titleMargin="24px 16px 20px 16px"
              icon={images.money_coins}
              iconSize="sm"
              zIndex={6}
            >
              <div className={styles.filter_card}>
                <Dropdown
                  data={options?.currencies ?? []}
                  added={filterList.currency ?? []}
                  setAdded={(item) => {
                    HandleFilterListSelectedChange({
                      param: "currency",
                      item,
                    });
                  }}
                  singleSelected
                  getCarriers={getCarriers}
                />
              </div>
            </Card>
          </div>
          <div className={styles.pos_comp_card_wrapper}>
            <Card
              variant="primary"
              title="Competitor"
              fontSize={16}
              titleMargin="24px 16px 20px 16px"
              icon={images.chess}
              iconSize="sm"
              // width={528}
              // height={188}
              zIndex={5}
            >
              <div className={styles.filter_card}>
                <div className={styles.competitors}>
                  <div className={styles.comp_wrapper}>
                    <span
                      className={styles.main_competitor_text}
                      data-theme={theme}
                    >
                      Main
                      <span style={{ margin: "0 4px" }}>{"*"}</span>:
                    </span>
                    <div className={styles.main_competitor}>
                      <Dropdown
                        data={options?.main_competitor ?? []}
                        added={filterList.mainCompetitor}
                        setAdded={(item) => {
                          HandleFilterListSelectedChange({
                            param: "mainCompetitor",
                            item,
                          });
                        }}
                        disabled={
                          filterList.destRegion.length < 1 &&
                          filterList.destCountry.length < 1 &&
                          filterList.destCityAirport.length < 1
                        }
                        singleSelected
                        border
                        getCarriers={getCarriers}
                        required={filterList.mainCompetitor.length < 1}
                      />
                    </div>
                  </div>
                  <div className={styles.comp_wrapper}>
                    <span
                      className={styles.other_competitor_text}
                      data-theme={theme}
                    >
                      Other
                      <span style={{ margin: "0 4px" }}>{"*"}</span>:
                    </span>
                    <div className={styles.other_competitor}>
                      <Dropdown
                        data={options?.selected_competitors ?? []}
                        added={filterList.selectedCompetitors}
                        setAdded={(item) => {
                          HandleFilterListSelectedChange({
                            param: "selectedCompetitors",
                            item,
                          });
                        }}
                        disabled={
                          filterList.destRegion.length < 1 &&
                          filterList.destCountry.length < 1 &&
                          filterList.destCityAirport.length < 1
                        }
                        border
                        getCarriers={getCarriers}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.card_wrapper}>
            <Card
              variant="primary"
              title="Sales Channel"
              fontSize={16}
              titleMargin="24px 16px 20px 20px"
              icon={images.hand_money_banknote}
              iconSize="sm"
              // width={349}
              height={124}
              zIndex={4}
            >
              <div className={styles.filter_card}>
                <Dropdown
                  data={options?.sales_channel ?? []}
                  added={filterList.salesChannel}
                  setAdded={(item) => {
                    if (
                      filterList.destRegion.length > 0 ||
                      filterList.destCountry.length > 0 ||
                      (filterList.destCityAirport.length > 0 &&
                        item.length === 0)
                    ) {
                      HandleFilterListSelectedChange({
                        param: "salesChannel",
                        item: ["All"],
                      });
                      return;
                    }
                    HandleFilterListSelectedChange({
                      param: "salesChannel",
                      item,
                    });
                  }}
                  disabled={
                    filterList.destRegion.length < 1 &&
                    filterList.destCountry.length < 1 &&
                    filterList.destCityAirport.length < 1
                  }
                  getCarriers={getCarriers}
                />
              </div>
            </Card>
          </div>
          <div className={styles.card_wrapper}>
            <Card
              variant="primary"
              title="Cabin"
              fontSize={16}
              titleMargin="24px 16px 20px 20px"
              icon={images.travel_plane_boarding_pass}
              iconSize="sm"
              // width={349}
              height={124}
              zIndex={1}
            >
              <div className={styles.filter_buttons_wrapper}>
                {options?.cabin?.map((item) => {
                  return (
                    <Button
                      key={item}
                      data={item}
                      added={filterList.cabin}
                      setAdded={(item) => {
                        HandleFilterListSelectedChange({
                          param: "cabin",
                          item,
                        });
                      }}
                      disabled={
                        filterList.destRegion.length < 1 &&
                        filterList.destCountry.length < 1 &&
                        filterList.destCityAirport.length < 1
                      }
                      width={70}
                      text="center"
                    >
                      {item}
                    </Button>
                  );
                })}
              </div>
            </Card>
          </div>
          <div className={styles.card_wrapper}>
            <Card
              variant="primary"
              title="Passenger Type"
              fontSize={16}
              titleMargin="24px 16px 20px 20px"
              icon={images.travel_plane_boarding_pass}
              iconSize="sm"
              // width={349}
              height={124}
              zIndex={1}
            >
              <div className={styles.filter_buttons_wrapper}>
                {options?.pax_type?.map((item) => {
                  return (
                    <Button
                      key={item}
                      data={item}
                      added={filterList.paxType}
                      setAdded={(item) => {
                        HandleFilterListSelectedChange({
                          param: "paxType",
                          item,
                        });
                      }}
                      disabled={
                        filterList.destRegion.length < 1 &&
                        filterList.destCountry.length < 1 &&
                        filterList.destCityAirport.length < 1
                      }
                      width={70}
                      text="center"
                    >
                      {item}
                    </Button>
                  );
                })}
              </div>
            </Card>
          </div>
        </div>
      </Card>
    </div>
  );
}
