import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { useRef } from "react";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

interface ILineChart {
  data: any;
  tension?: number;
  width?: number | string;
  height?: number | string;
  radius?: number;
  lineColor: string;
  gradientColorArray: any;
}

export default function LineMiniChart({
  data,
  tension = 0.5,
  width,
  height,
  radius = 0,
  lineColor,
  gradientColorArray,
}: ILineChart) {
  const chartRef = useRef();
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "",
      },
      tooltip: { enabled: false },
    },
    scales: {
      y: {
        drawTicks: false,
        ticks: {
          display: false,
        },
        grid: {
          display: false,
          drawBorder: false,
        },
        beginAtZero: false,
      },
      x: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
    },
  };

  const getGradientt = (lineColor) => {
    const chart: any = chartRef.current;
    if (!chart) {
      return;
    }
    return createGradient(chart.ctx, lineColor);
  };

  const createGradient = (ctx, color) => {
    // ctx.createLinearGradient(x0, y0, x1, y1);
    //ctx.createRadialGradient(x0, y0, r0, x1, y1, r1);
    const gradient = ctx.createLinearGradient(0, -90, 0, 90);
    let opacity = `${color}00`;
    gradient.addColorStop(0, color);
    gradient.addColorStop(0.7, opacity);
    return gradient;
  };

  let gradient;
  function getGradient(ctx, chartArea) {
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (!gradient || width !== chartWidth || height !== chartHeight) {
      // Create the gradient because this is either the first render
      // or the size of the chart has changed
      width = chartWidth;
      height = chartHeight;
      gradient = ctx.createLinearGradient(
        0,
        chartArea.bottom,
        0,
        chartArea.top
      );
      gradient.addColorStop(0, gradientColorArray[0]);
      gradient.addColorStop(0.5, gradientColorArray[1]);
      gradient.addColorStop(1, gradientColorArray[2]);
    }
    return gradient;
  }

  const x = data?.map((key) => key?.x);
  const y = data?.map((key) => key?.y);
  const array = {
    labels: x,
    datasets: [
      {
        data: y,
        fill: true,
        backgroundColor: getGradientt(lineColor),
        borderWidth: 2,
        borderColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            // This case happens on initial chart load
            return;
          }
          return getGradient(ctx, chartArea);
        },
        radius: radius,
        pointBackgroundColor: "#000",
        tension: tension,
      },
    ],
  };
  return (
    <Line
      ref={chartRef}
      options={options}
      data={array}
      width={width}
      height={height}
    />
  );
}
