import { IFilterContext } from "types/type";
import { QueryParameterStringBuilder } from "lib/query-parameter-string-builder";
import { RestClient } from "../../RestClient";

interface IBookingCurveAnalysis {
  filterList: IFilterContext;
  graphCarriers: string;
  time_direction: string;
  selected_yearmonth: string;
  agg_type: string;
  val_type: string;
  dtd: string;
  dark_theme: boolean;
}

export class BookingCurveAnalysisClient {
  private static _client: RestClient;
  static fetchBookingCurveAnalysis({
    filterList,
    graphCarriers,
    time_direction,
    selected_yearmonth,
    agg_type,
    val_type,
    dtd,
    dark_theme,
  }: IBookingCurveAnalysis) {
    const qp = QueryParameterStringBuilder.apply(filterList);
    return BookingCurveAnalysisClient.getClient().getCall(
      `/api/msdv2/booking-trends/booking-curve?graph_carriers=${graphCarriers}&time_direction=${time_direction}&selected_yearmonth=${selected_yearmonth}&val_type=${val_type}&dtd=${dtd}&agg_type=${agg_type}&${qp}&dark_theme=${dark_theme}`
    );
  }
  static getClient() {
    if (BookingCurveAnalysisClient._client == null) {
      BookingCurveAnalysisClient._client = new RestClient();
    }
    return BookingCurveAnalysisClient._client;
  }
}
