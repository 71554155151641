/* eslint-disable @typescript-eslint/no-unused-vars */
import styles from "./styles.module.css";
import { useContext, useRef, useState } from "react";
import { Avatar } from "@mui/material";
import images from "constans/images";
import { useCookies } from "react-cookie";
import { ThemeContext } from "context-api/ThemeContext";
import { Logout } from "@mui/icons-material";
import { UserProfileContext } from "context-api/UserProfile";
import { useOutsideClick } from "hooks/useOutsideClick";
import LazyLoad from "react-lazyload";

export default function HomeSelect() {
  const [selectedModule, setSelectedModule] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef: any = useRef(null);
  const [_, setCookie] = useCookies(["token", "module"]);
  const { theme, setTheme } = useContext(ThemeContext);
  const { user } = useContext(UserProfileContext);

  useOutsideClick(wrapperRef, () => setIsOpen(false));

  const handleClickExplorerResult = () => {
    window.location.href = user?.defaultPage ?? "/home"; // redirect to default page
  };

  const handleLogout = () => {
    localStorage.removeItem("user"); // remove user from local storage
    setCookie("token", ""); // remove token from cookie
  };

  return (
    <div className={styles.container}>
      <div className={styles.image_wrapper}>
        <div className={styles.user_card} ref={wrapperRef} data-theme={theme}>
          <div
            className={styles.open_menu}
            data-theme={theme}
            data-active={isOpen}
          >
            <div className={styles.theme_button_wrapper} data-theme={theme}>
              <span
                className={styles.dark_button}
                data-active={theme === "dark"}
                onClick={() => {
                  setTheme("dark");
                  localStorage.setItem("theme", "dark");
                  document.body.className = "dark";
                }}
                data-theme={theme}
              >
                Dark
              </span>
              <span
                className={styles.light_button}
                data-active={theme === "light"}
                onClick={() => {
                  setTheme("light");
                  localStorage.setItem("theme", "light");
                  document.body.className = "light";
                }}
                data-theme={theme}
              >
                Light
              </span>
            </div>
            <div
              className={styles.user_logout_wrapper}
              data-theme={theme}
              onClick={handleLogout}
            >
              <Logout className={styles.logout_icon} data-theme={theme} />
              <span>Logout</span>
            </div>
          </div>
          <Avatar className={styles.avatar} />
          <div className={styles.user_name}>
            <p className={styles.name}>{user?.displayName}</p>
            <p className={styles.airline_name}>{user?.clientName}</p>
          </div>
          <div>
            <i
              className={styles.arrow}
              data-theme={theme}
              onClick={() => setIsOpen(!isOpen)}
            ></i>
          </div>
        </div>
        <LazyLoad height={200} offset={100}>
          <img
            src={theme === "dark" ? images.world : images.world_light}
            alt=""
            loading="lazy"
          />
        </LazyLoad>
      </div>
      <div className={styles.select_card} data-theme={theme}>
        <div className={styles.user_and_logo_wrapper}>
          <span className={styles.user_welcome_text}>
            Hi, {user?.displayName}
          </span>
          <LazyLoad height={200} offset={100}>
            <img
              src={theme === "dark" ? images.logo : images.logo_light}
              alt=""
              className={styles.logo}
              loading="lazy"
            />
          </LazyLoad>
        </div>
        <div className={styles.map_wrapper}>
          <LazyLoad height={200} offset={100}>
            <img src={images.worldHexbin} alt="" loading="lazy" />
          </LazyLoad>
        </div>
        <div className={styles.select_input_wrapper}>
          <div className={styles.social_media_icons_wrapper}>
            <a
              href="https://www.linkedin.com/company/atarev-software-solutions/"
              target={"_blank"}
              rel="noreferrer"
            >
              <img
                src={images.linkedin}
                alt=""
                className={styles.social_media_icons}
              />
            </a>
            <img
              src={images.instagram}
              alt=""
              className={styles.social_media_icons}
            />
            <img
              src={images.twitter}
              alt=""
              className={styles.social_media_icons}
            />
          </div>
          <div className={styles.select_input_card_wrapper}>
            <span
              className={styles.revenue_suit_button}
              data-active={selectedModule}
              onClick={() => {
                setSelectedModule(!selectedModule);
              }}
              data-theme={theme}
            >
              <span className={styles.rs} data-theme={theme}>
                RS
              </span>
            </span>
            <button
              className={styles.explorer_result_wrapper}
              data-active={selectedModule}
              onClick={handleClickExplorerResult}
              data-theme={theme}
              disabled={!selectedModule}
            >
              <span>Explore Results</span>
              <img
                src={images.logout_arrow}
                alt=""
                className={styles.arrow_icon}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
