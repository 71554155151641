import { RestClient } from "../../RestClient";

export class HolidaysFieldsClient {
  private static _client: RestClient;

  static fetchHolidaysFields({ params }) {
    return HolidaysFieldsClient.getClient().getCall(
      `/api/msdv2/events/fields?${new URLSearchParams(params)}`
    );
  }

  static getClient() {
    if (HolidaysFieldsClient._client == null) {
      HolidaysFieldsClient._client = new RestClient();
    }
    return HolidaysFieldsClient._client;
  }
}
