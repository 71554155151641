import { ThemeContext } from "context-api/ThemeContext";
import { memo, useContext } from "react";
import DataTable, { createTheme } from "react-data-table-component";

createTheme(
  "custom_atarev_dark",
  {
    text: {
      primary: "rgba(255, 255, 255, 0.6)",
      secondary: "#fff",
    },
    background: {
      default: "transparent",
    },
    context: {
      background: "#cb4b16",
      text: "#FFFFFF",
    },
    divider: {
      default: "transparent",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  },
  "dark"
);
createTheme(
  "custom_atarev_light",
  {
    text: {
      primary: "var(--text-color-primary-light)",
      secondary: "var(--text-color-secondary-light)",
    },
    background: {
      default: "transparent",
    },
    context: {
      background: "#cb4b16",
      text: "var(--text-color-primary-light)",
    },
    divider: {
      default: "transparent",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  },
  "light"
);

interface ITable {
  data: any;
  columns: any;
  selectableRows?: boolean;
  noRowsPerPage?: boolean;
  setSelected?: any;
  paginationPerPage?: number;
  pagination?: boolean;
  rowBorder?: boolean;
  nthChild?: boolean;
  defaultSelection?: any;
  clearSelectedRows?: boolean;
  conditionalRowStyles?: any[] | undefined;
  onRowClicked?: any;
  pointerOnHover?: boolean;
}

const TableComponent = ({
  data,
  columns,
  selectableRows = false,
  noRowsPerPage = true,
  setSelected,
  paginationPerPage,
  pagination = false,
  rowBorder = false,
  nthChild = false,
  defaultSelection = undefined,
  clearSelectedRows = false,
  conditionalRowStyles = undefined,
  onRowClicked,
  pointerOnHover = false,
}: ITable) => {
  const { theme } = useContext(ThemeContext);
  const customStyles = {
    headRow: {
      style: {
        minHeight: "32px",
        background:
          theme === "dark"
            ? "rgba(0, 0, 0, 0.2)"
            : "var(--button-bg-color-primary-light)",
        borderRadius: 5,
      },
    },
    headCells: {
      style: {
        height: "32px", // override the row height
        paddingLeft: "16px", // override the cell padding for head cells
        paddingRight: "4px",
        fontFamily: "var(--font-family-light)",
        fontSize: 12,
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color:
          theme === "dark"
            ? "rgba(255, 255, 255, 0.6)"
            : "var(--text-color-primary-light)",
      },
    },
    rows: {
      style: {
        height: "48px", // override the row height
        margin: "4px 0 0 0",
        background:
          theme === "dark"
            ? "rgba(255, 255, 255, 0.05)"
            : "rgba(0, 0, 0, 0.05)",
        borderRadius: 5,
        border: rowBorder
          ? "1px solid rgba(255, 255, 255, 0.1) !important"
          : "none",
        ":nth-child(even)": {
          background: nthChild
            ? "none"
            : theme === "dark"
            ? "rgba(255,255,255,0.05)"
            : "rgba(0,0,0,0.05)",
        },
      },
      highlightOnHoverStyle: {
        backgroundColor:
          theme === "dark" ? "rgba(255,255,255,0.1)" : "rgba(85, 4, 217, 0.1)",
        borderRadius: "5px",
      },
    },
    cells: {
      style: {
        paddingLeft: "16px", // override the cell padding for data cells
        paddingRight: "4px",
        fontFamily: "Fabriga",
        fontSize: 14,
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "var(--text-color-primary)",
      },
    },
    pagination: {
      style: {
        minHeight: "32px",
        color: "var(--text-color-primary)",
      },
    },
  };

  const paginationComponentOptions = {
    noRowsPerPage: noRowsPerPage,
  };
  const handleChange = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    setSelected(state.selectedRows);
  };

  const handleRowClick = (row) => {
    if (onRowClicked) {
      onRowClicked(row);
    }
  };

  return (
    <DataTable
      theme={theme === "dark" ? "custom_atarev_dark" : "custom_atarev_light"}
      customStyles={customStyles}
      columns={columns}
      data={data}
      pagination={pagination}
      paginationPerPage={paginationPerPage || 10}
      paginationComponentOptions={paginationComponentOptions}
      selectableRows={selectableRows} // true ya da false alır. Tabloyu seçilebilir yapar.
      selectableRowSelected={
        defaultSelection ? () => defaultSelection : undefined
      }
      onSelectedRowsChange={handleChange}
      selectableRowsNoSelectAll={true} //Tümünü seç butonunu disable eder.
      clearSelectedRows={clearSelectedRows}
      highlightOnHover
      conditionalRowStyles={conditionalRowStyles}
      onRowClicked={handleRowClick}
      pointerOnHover={pointerOnHover}
    />
  );
};
export default memo(TableComponent);
