import AverageAndFareTrends from "components/msd/AverageAndFareTrends/AverageAndFareTrends";
import KPIMini from "components/msd/KPIMini/KPIMini";
import RBDPriceElasticitiesComparison from "components/msd/RBDPriceElasticitiesComparison/RBDPriceElasticitiesComparison";
import { useState } from "react";
import BookingsVsAverageFares from "components/msd/BookingsVsAverageFares/BookingsVsAverageFares";
import MonthlyRevenueAnalysis from "components/msd/MonthlyRevenueAnalysis/MonthlyRevenueAnalysis";
import DaysOfWeekRevenueAnalysis from "components/msd/DaysOfWeekRevenueAnalysis/DaysOfWeekRevenueAnalysis";
import ClassMix from "components/msd/ClassMix/ClassMix";
import Layout from "components/common/Layout/Layout";

export default function FAREREVENUE() {
  const [filterIsOpen, setFilterIsOpen] = useState(true);

  return (
    <Layout
      setFilterIsOpen={setFilterIsOpen}
      filterIsOpen={filterIsOpen}
      title="Fare Revenue"
    >
      <KPIMini filterIsOpen={filterIsOpen} />
      <div style={{ display: "flex", gap: 16 }}>
        <AverageAndFareTrends filterIsOpen={filterIsOpen} />
        <RBDPriceElasticitiesComparison filterIsOpen={filterIsOpen} />
      </div>
      <BookingsVsAverageFares filterIsOpen={filterIsOpen} />
      <div style={{ display: "flex", gap: 16 }}>
        <MonthlyRevenueAnalysis filterIsOpen={filterIsOpen} />
        <DaysOfWeekRevenueAnalysis filterIsOpen={filterIsOpen} />
      </div>
      <ClassMix filterIsOpen={filterIsOpen} />
    </Layout>
  );
}
