/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./styles.module.css";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { HolidaysFilterClient } from "lib/api/ea/filter/GetFilterOptions";
import { CiStar } from "react-icons/ci";
import SideDrawer from "components/common/SideDrawer";
import MenuItem from "./FilterMenu";
import { EAFilterContext } from "context-api/FilterContextEA";
import { UserProfileContext } from "context-api/UserProfile";
import DropdownCountryCity from "components/common/Dropdown/DropdownCountryCity";
import { HolidaysFilterSelectedSaveClient } from "lib/api/ea/filter/PostFilterOptions";
import moment from "moment";
import Skeleton from "components/common/Skeleton";

interface ICategories {
  label: string;
  value: string;
  sub_categories: {
    label: string;
    value: string;
    events: {
      event_name: string;
      event_id: string;
      selected: boolean;
      group_id: null | number;
    }[];
  }[];
}
interface IProps {
  dest_required?: boolean;
  date?: moment.Moment;
  detailDate?: moment.Moment;
  setDetailDate?: Dispatch<SetStateAction<moment.Moment>>;
  currentDate?: moment.Moment;
  prevDate?: moment.Moment;
  isSummaryDetail?: boolean;
}

const HolidaysFilter = ({
  dest_required = false,
  date = undefined,
  currentDate = undefined,
  prevDate = undefined,
  detailDate,
  isSummaryDetail = false,
}: IProps) => {
  const [origin, setOrigin] = useState<any[]>([]);
  const [destination, setDestination] = useState<any[]>([]);
  const [categories, setCategories] = useState<ICategories[]>([]);
  const [categoriesInitial, setCategoriesInitial] = useState<ICategories[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchCountryCityOrigin, setSearchCountryCityOrigin] = useState("");
  const [searchCountryCityDestination, setSearchCountryCityDestination] =
    useState("");
  const [expandSubCategory, setExpandSubCategory] = useState<{
    [key: string]: boolean;
  }>({});
  const [skeletonLoader, setSkeletonLoader] = useState(true);

  const { user } = useContext(UserProfileContext);
  const { eaFilterList, setEaFilterList } = useContext(EAFilterContext);

  const drawerRef = useRef<{ closeDrawer: () => void }>(null);

  const handleCloseDrawer = () => {
    if (drawerRef.current) {
      drawerRef.current.closeDrawer();
    }
  };

  useEffect(() => {
    if (categoriesInitial.length < 1) {
      const initialExpandSubCategoryState: { [key: string]: boolean } = {};
      categories.forEach((category) => {
        category.sub_categories.forEach((subCategory) => {
          initialExpandSubCategoryState[subCategory.value] = false;
        });
      });
      setExpandSubCategory(initialExpandSubCategoryState);
    }
  }, [categoriesInitial]);

  useEffect(() => {
    if (user) {
      setEaFilterList((prevState) => ({
        ...prevState,
        origin_city: user.defaultFilterSelection.ea.origCityAirport,
        destination: user.defaultFilterSelection.ea.destCityAirport,
      }));
    }
  }, [user?.defaultFilterSelection]);

  const getHolidaysOptions = () => {
    const params = {
      target: "ea",
      origin_city: eaFilterList.origin_city,
      destination: eaFilterList.destination,
      lookup: searchTerm,
      orig_city_airport_lookup: searchCountryCityOrigin,
      dest_city_airport_lookup: searchCountryCityDestination,
      ...(date && {
        ...(isSummaryDetail
          ? {
              month: moment(detailDate).format("M"),
              year: moment(detailDate).format("YYYY"),
            }
          : {
              date_range_start: moment(date).format("YYYY-MM-DD"),
              date_range_end: moment(date).add(1, "year").format("YYYY-MM-DD"),
            }),
      }),
      ...(currentDate &&
        prevDate && {
          current_date: moment(currentDate).format("YYYY-MM-DD"),
          prev_date: moment(prevDate).format("YYYY-MM-DD"),
        }),
    };
    HolidaysFilterClient.fetchFilterOptions(params)
      .then((res: any) => {
        const origins = [
          ...res?.origins?.map((el) => ({
            code: el?.country_code,
            name: el?.country_name,
            airports: [
              ...el?.cities.map((city) => ({
                airport_name: city?.city_name,
                airport_code: city?.city_code,
                city_code: el?.country_code,
              })),
            ],
          })),
        ];
        const destinations = [
          ...res?.destinations?.map((el) => ({
            code: el?.country_code,
            name: el?.country_name,
            airports: [
              ...el?.cities.map((city) => ({
                airport_name: city?.city_name,
                airport_code: city?.city_code,
                city_code: el?.country_code,
              })),
            ],
          })),
        ];
        const responseCategories: ICategories[] = res.categories ?? [];
        let selecteds: string[] = [];
        let selectedGroups: number[] = [];
        responseCategories?.forEach((category) =>
          category?.sub_categories?.forEach((sub) =>
            sub?.events?.forEach((event) => {
              if (event.selected && !selecteds.includes(event.event_id)) {
                selecteds.push(event.event_id);
                event.group_id && selectedGroups.push(event.group_id);
              }
            })
          )
        );

        if (selecteds !== eaFilterList.event_ids)
          setEaFilterList((prevState) => ({
            ...prevState,
            event_ids: selecteds,
            group_ids: selectedGroups,
          }));
        setCategories(res?.categories ?? []);
        setCategoriesInitial(res?.categories ?? []);
        setOrigin(origins);
        setDestination(destinations);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setTimeout(() => {
          setSkeletonLoader(false);
        }, 2000);
      });
  };

  useEffect(() => {
    getHolidaysOptions();
  }, [
    eaFilterList.origin_city,
    eaFilterList.destination,
    searchTerm,
    searchCountryCityOrigin,
    searchCountryCityDestination,
    date,
    detailDate,
    isSummaryDetail,
  ]);

  const handleSave = () => {
    let data = {
      category: eaFilterList.category,
      sub_category: eaFilterList.subCategory,
      event_ids: eaFilterList.ids,
      group_ids: eaFilterList.groupIds,
    };
    HolidaysFilterSelectedSaveClient.fetchFilterSelectedSave(data)
      .then(() => {
        setEaFilterList((prevState) => ({
          ...prevState,
          updated: !prevState.updated,
        }));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        handleCloseDrawer();
      });
  };

  const handleClickOptions = (isClear: boolean) => {
    let selected: string[] = [];
    categories.forEach((cat) =>
      cat.sub_categories.forEach((sub) =>
        sub.events.forEach((olay) => selected.push(olay.event_id))
      )
    );
    setEaFilterList((prevState) => ({
      ...prevState,
      event_ids: isClear ? [] : selected,
      group_ids: isClear ? [] : [],
    }));
  };

  //fonksiyonlardan önce kurulum
  let typingTimer; //zamanlayıcı tanımlayıcısı

  //keyup'ta geri sayımı başlat
  const onKeyUp = (event) => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(() => handleSearchFilter(event), 1000);
  };

  //tuşa basıldığında, geri sayımı temizle
  const onKeyDown = () => {
    clearTimeout(typingTimer);
  };

  const handleSearchFilter = (event) => {
    setSearchTerm(event?.target?.value);
  };

  return skeletonLoader ? (
    <Skeleton width={"100%"} height={64} />
  ) : (
    <div className={styles.contanier}>
      <div className={styles.dropdown_wrapper}>
        <span>Origin</span>
        <DropdownCountryCity
          data={origin}
          added={eaFilterList.origin_city}
          setAdded={(item: any) => {
            setEaFilterList((prevState) => ({
              ...prevState,
              origin_city: item,
            }));
          }}
          singleSelected
          setSearchFilter={(str) => {
            setSearchCountryCityOrigin(str);
          }}
          isCountryAndCity
        />
      </div>
      <div className={styles.dropdown_wrapper}>
        <span>Destination</span>
        <DropdownCountryCity
          data={destination}
          added={eaFilterList.destination}
          setAdded={(item: any) => {
            setEaFilterList((prevState) => ({
              ...prevState,
              destination: item,
            }));
          }}
          disabled={eaFilterList.origin_city.length < 1}
          singleSelected
          setSearchFilter={(str) => {
            setSearchCountryCityDestination(str);
          }}
          isCountryAndCity
          required={dest_required && eaFilterList.destination.length < 1}
        />
      </div>
      <div className={styles.button_wrapper}>
        <SideDrawer
          ref={drawerRef}
          MenuButton={
            <span className={styles.setting_button}>
              <CiStar size={24} />
            </span>
          }
          direction="right"
          width={450}
          shutdownTrigger={() => {
            handleSave();
          }}
        >
          <div className={styles.menu_wrapper}>
            <h3 className={styles.menu_title}>Event Categories</h3>
            <button onClick={() => handleClickOptions(true)}>Clear All</button>
            {/* <button onClick={() => handleClickOptions(false)}>
              Select All
            </button> */}
            <button onClick={handleSave}>Apply Filter</button>
            <input
              type="text"
              placeholder="Search..."
              onKeyUp={onKeyUp}
              onKeyDown={onKeyDown}
            />
            {categories.map((category, index) => (
              <MenuItem
                key={index}
                category={category}
                expandSubCategory={expandSubCategory}
                setExpandSubCategory={setExpandSubCategory}
              />
            ))}
          </div>
        </SideDrawer>
      </div>
    </div>
  );
};

export default HolidaysFilter;
