import Card from "components/common/Card/Card";
import styles from "./styles.module.css";
import images from "constans/images";
import { useContext } from "react";
import { ThemeContext } from "context-api/ThemeContext";
import Skeleton from "components/common/Skeleton";

interface ICompetitiveDiagnostic {
  data: { title: string; value: string[] };
  skeletonLoader: boolean;
}
export default function CompetitiveDiagnostic({
  data,
  skeletonLoader,
}: ICompetitiveDiagnostic) {
  const { theme } = useContext(ThemeContext);

  return (
    <Card
      variant="secondary"
      width={"100%"}
      height={144}
      borderRadius="50px 15px 15px 15px"
      borderColor="#5504d9"
    >
      {skeletonLoader ? (
        <Skeleton width={"100%"} height={128} />
      ) : (
        <div className={styles.container} data-theme={theme}>
          <span className={styles.icon_wrapper}>
            <img src={images.legal_law} alt="" />
          </span>
          <div className={styles.title_content_wrapper} data-theme={theme}>
            <span>Competitive Diagnostic</span>
            <p>{data?.value[0] ? data?.value[0] : ""}</p>
          </div>
        </div>
      )}
    </Card>
  );
}
