import { DatePicker, Select, TimePicker } from "antd";
import React from "react";
import { TableColumn } from "../type";
import Input from "components/common/Input/input";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;

interface FilterState {
  filterKey: string;
  filterValues: string[] | number[];
}

interface ColumnFilterProps {
  columnType: "text" | "date" | "number" | "select" | "time";
  allColumns: TableColumn[];
  sm: FilterState;
  index: number;
  onFilterChange: (e: any, sm: FilterState, i: number) => void;
}

const ColumnFilter: React.FC<ColumnFilterProps> = ({
  columnType,
  allColumns,
  sm,
  index,
  onFilterChange,
}) => {
  // Ortak Select props'ları için yardımcı fonksiyon
  const renderSelect = (
    mode: "tags" | "multiple",
    placeholder: string,
    maxCount?: number
  ) => (
    <Select
      mode={mode}
      size="small"
      allowClear
      style={{ width: 150 }}
      placeholder={placeholder}
      maxCount={maxCount}
      value={sm.filterValues}
      onChange={(value) => onFilterChange(value, sm, index)}
      maxTagCount="responsive"
      filterOption={(input, option) =>
        (option?.label ?? "")?.toLowerCase()?.includes(input?.toLowerCase())
      }
      options={
        allColumns.find((col) => col.key === sm.filterKey)?.options ?? []
      }
      notFoundContent={
        mode === "tags"
          ? "Insert with enter if there are no options"
          : undefined
      }
    />
  );

  switch (columnType) {
    case "text":
      return renderSelect("tags", "Please search");

    case "date":
      return (
        <RangePicker
          format={"DD-MM-YYYY"}
          value={
            sm.filterValues.length > 0
              ? [
                  dayjs(sm.filterValues[0], "DD-MM-YYYY"),
                  dayjs(sm.filterValues[1], "DD-MM-YYYY"),
                ]
              : null
          }
          onChange={(date) => {
            const value = date
              ? [date[0]?.format("DD-MM-YYYY"), date[1]?.format("DD-MM-YYYY")]
              : [];
            onFilterChange(value, sm, index);
          }}
        />
      );
    case "time":
      return (
        <TimePicker.RangePicker
          format="HH:mm"
          value={
            sm.filterValues.length > 0
              ? [
                  dayjs(sm.filterValues[0], "HH:mm"),
                  dayjs(sm.filterValues[1], "HH:mm"),
                ]
              : null
          }
          onChange={(time) => {
            const value = time
              ? [time[0]?.format("HH:mm"), time[1]?.format("HH:mm")]
              : [];
            onFilterChange(value, sm, index);
          }}
        />
      );

    case "number":
      const range = allColumns.find((col) => col.key === sm.filterKey)?.range;
      return (
        <div style={{ display: "flex", gap: 5 }}>
          <Input
            type="number"
            placeholder={`Min: ${
              Array.isArray(range) && Math.round(Number(range[0]))
            }`}
            min={
              Array.isArray(range) ? Math.round(Number(range[0])) : undefined
            }
            max={
              Array.isArray(range) ? Math.round(Number(range[1])) : undefined
            }
            value={sm.filterValues[0] || ""}
            onChange={(e) => {
              let newValue: any = e.target.value;
              if (newValue === null || newValue === "") {
                newValue = null;
              } else newValue = Number(newValue);
              onFilterChange([newValue, sm.filterValues[1]], sm, index);
            }}
            style={{ width: 100 }}
          />
          <Input
            type="number"
            placeholder={`Max: ${
              Array.isArray(range) && Math.round(Number(range[1]))
            }`}
            min={
              Array.isArray(range) ? Math.round(Number(range[0])) : undefined
            }
            max={
              Array.isArray(range) ? Math.round(Number(range[1])) : undefined
            }
            value={sm.filterValues[1] || ""}
            onChange={(e) => {
              let newValue: any = e.target.value;
              if (newValue === null || newValue === "") {
                newValue = null;
              } else newValue = Number(newValue);
              onFilterChange([sm.filterValues[0], newValue], sm, index);
            }}
            style={{
              width: 100,
            }}
          />
        </div>
      );

    case "select":
      return renderSelect("multiple", "Please select");

    default:
      return <div>Unsupported filter type</div>;
  }
};

export default ColumnFilter;
