import { IFilterContext } from "types/type";
import { QueryParameterStringBuilder } from "lib/query-parameter-string-builder";
import { RestClient } from "../../RestClient";

interface IPerformanceTrendsLoadFactorCurveClient {
  filterList: IFilterContext;
  selected_yearmonth: string;
  val_type: string;
  dtd: string;
  dark_theme: boolean;
}

export class PerformanceTrendsLoadFactorCurveClient {
  private static _client: RestClient;
  static fetchPerformanceTrendsLoadFactorCurveClient({
    filterList,
    selected_yearmonth,
    val_type,
    dtd,
    dark_theme,
  }: IPerformanceTrendsLoadFactorCurveClient) {
    const qp = QueryParameterStringBuilder.apply(filterList);
    return PerformanceTrendsLoadFactorCurveClient.getClient().getCall(
      `/api/msdv1/performanceTrends_loadFactorCurve?selected_yearmonth=${selected_yearmonth}&val_type=${val_type}&dtd=${dtd}&${qp}&dark_theme=${dark_theme}`
    );
  }
  static getClient() {
    if (PerformanceTrendsLoadFactorCurveClient._client == null) {
      PerformanceTrendsLoadFactorCurveClient._client = new RestClient();
    }
    return PerformanceTrendsLoadFactorCurveClient._client;
  }
}
