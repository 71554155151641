/* eslint-disable react-hooks/exhaustive-deps */
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import "App.css";
import RouterList from "RouteList";
import { FilterContext } from "context-api/FilterContext";
import { ThemeContext } from "context-api/ThemeContext";
import { UserProfileContext } from "context-api/UserProfile";
import { AtarevProgressLinearContext } from "context-api/AtarevProgressLinear";
import { UserClient } from "lib/api/user/user";
import { IRoute } from "types/route";
import { ProtectedRoute } from "components/protectedRoute";
import RulesPriority from "components/lfa/RulesComponents/RulesPriority";
import ProgressLinear from "components/progressLinear";
import LandingPage from "./components/landingPage";
import LOGIN from "modules/login";
import HOME from "modules/home";
import TestPage from "modules/test-page/index-fa";
import AdminPage from "modules/test-page/index-filter-scraper-config";
import { UserActivityClient } from "lib/api/user/user-activity";

export default function App() {
  const defaultRoute = {
    icon: "",
    name: "",
    path: "",
    disabled: false,
    component: () => <span></span>,
  };
  const bodyRef = useRef<any>(null);
  const [cookies, setCookie] = useCookies(["token"]);
  const [routes, setRoutes] = useState<IRoute[]>([defaultRoute]);
  const [pathname, setPathname] = useState(window.location.pathname);
  const [oldPathname, setOldPathname] = useState(window.location.pathname);
  const [currentModule, setCurrentModule] = useState<string | null>(null);
  const [timesOfPause, setTimesOfPause] = useState<
    { startDate: Date | null; endDate: Date | null }[]
  >([]);

  const inactivityTimer = useRef<NodeJS.Timeout | null>(null); // Hareketsizliği kontrol eden zamanlayıcı
  const startTime: any = useRef(new Date()); // Başlangıç zamanını saklamak için

  const { theme, setTheme } = useContext(ThemeContext);
  const { isProgress, setIsProgress } = useContext(AtarevProgressLinearContext);
  const { filterList, setFilterList } = useContext(FilterContext);
  const { user, setUser, setUserLoader } = useContext(UserProfileContext);

  const location = window.location;

  useEffect(() => {
    let isTracking = true; // Kullanıcı etkinliğini izleyip izlemediğimizi belirtir.
    let inactiveTime: number | null = null; // Kullanıcının hareketsiz kaldığı zamanı saklar.

    // Hareketsizlik zamanlayıcısını sıfırlayan fonksiyon
    const resetInactivityTimer = () => {
      if (!isTracking) {
        // Kullanıcı tekrar hareket ettiğinde izlemeyi başlat
        isTracking = true;

        if (inactiveTime) {
          // Kullanıcının hareketsiz kaldığı süreyi hesapla
          const inactiveDuration = new Date().getTime() - inactiveTime;

          // Hareketsizlik süresini başlangıç süresine ekle
          startTime.current = new Date(
            startTime.current.getTime() + inactiveDuration
          );

          // Hareketsizlik süresi boyunca geçirilen zamanı güncelle
          setTimesOfPause((prevDates) =>
            prevDates.map((item, index) =>
              index === prevDates.length - 1
                ? { ...item, endDate: new Date() }
                : item
            )
          );
        }
      }

      // Daha önce ayarlanmış olan hareketsizlik zamanlayıcısını temizle
      if (inactivityTimer.current) {
        clearTimeout(inactivityTimer.current);
      }

      // Yeni bir hareketsizlik zamanlayıcısı başlat
      inactivityTimer.current = setTimeout(() => {
        isTracking = false; // Kullanıcının hareketsiz durumda olduğunu belirle
        inactiveTime = new Date().getTime() - 300000; // Hareketsizliğin başladığı zamanı kaydet

        // Hareketsizlik süresini listeye ekle
        setTimesOfPause((prevState) => [
          ...prevState,
          { startDate: new Date(), endDate: null },
        ]);
      }, 300000); // 5 dakika boyunca hareket algılanmazsa tetiklenir
    };

    // Kullanıcının fare hareketlerini dinleyerek zamanlayıcıyı sıfırlar
    window.addEventListener("mousemove", resetInactivityTimer);

    // Cleanup işlemleri
    return () => {
      // Fare hareketlerini dinleyen event listener'ı kaldır
      window.removeEventListener("mousemove", resetInactivityTimer);

      // Zamanlayıcıyı temizle
      if (inactivityTimer.current) {
        clearTimeout(inactivityTimer.current);
      }
    };
  }, []); // useEffect yalnızca bileşen yüklendiğinde bir kez çalışır

  useEffect(() => {
    setIsProgress(true);
    if (bodyRef) {
      bodyRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
    const theme = localStorage.getItem("theme");
    if (theme) {
      document.body.className = theme;
      setTheme(theme);
    } else {
      localStorage.setItem("theme", "dark");
      document.body.className = "dark";
      setTheme("dark");
    }
    if (cookies.token && user === null) {
      setUserLoader(true);
      UserClient.fetchUser({ token: cookies.token })
        .then((res: any) => {
          const defaultSelectFilter = {
            ...filterList,
            ...res.defaultFilterSelection?.global,
          };
          setUser(res ?? null);

          localStorage.setItem(
            "user",
            JSON.stringify({
              ...res,
              defaultFilterSelection: defaultSelectFilter,
            })
          );

          setFilterList(defaultSelectFilter);

          // Backend'den gelen rotaların tutulacağı değişken
          let backendRoute: any = [];

          if (res?.enabledModules) {
            Object.keys(res?.enabledModules).forEach((module) => {
              //Eğer module etkin ise erişilebilecek tüm sayfaları alıyoruz.
              if (res?.enabledModules[module]?.enabled) {
                backendRoute.push(...res?.enabledModules[module]?.pages);
              }
            });

            // Ön uçtan comparative-analysis sayfasına erişimi kapatıyoruz.
            backendRoute = backendRoute.filter(
              (el) => el !== "/comparative-analysis"
            );

            // Rotalar backend'den geliyorsa erişime açıyoruz
            let newRoutes: IRoute[] = [];
            Object.keys(RouterList).forEach((module: string) => {
              RouterList[module].forEach((el: IRoute) => {
                newRoutes.push({
                  ...el,
                  disabled: !backendRoute.includes(el.path),
                });

                // Modulleri sistem içerisindeki dropdown kullanımları için tarayıcıya kaydediyoruz.
                if (window.location.pathname === el.path) {
                  localStorage.setItem("module", module);
                }
              });
            });
            setRoutes(newRoutes);
          } else setRoutes([defaultRoute]);
        })
        .catch(() => {
          localStorage.removeItem("user"); // remove user from local storage
          setCookie("token", "");
        })
        .finally(() => {
          setIsProgress(false);
          setTimeout(() => {
            setUserLoader(false);
          }, 2000);
        });
    } else setIsProgress(false);
  }, [pathname]);

  const getCurrentModule = () => {
    for (const [module, routes] of Object.entries(RouterList)) {
      if (routes.some((route) => route.path === pathname)) {
        return module; // Modül ismini döndür
      }
    }
    return null; // Modül bulunamazsa null döndür
  };

  useEffect(() => {
    // Sayfada geçirilen süreyi hesaplayan fonksiyon
    const calculateTimeSpent = () => {
      const endTime = new Date(); // Şu anki zamanı al
      const timeSpent = Math.round(
        (endTime.getTime() - startTime.current.getTime()) / 1000
      ); // Geçen süreyi saniye cinsine çevirerek hesapla

      // Kullanıcı etkinlik loglarını oluştur
      const logs = {
        module: currentModule, // Mevcut modül bilgisi
        page: oldPathname.slice(1), // Eski sayfa ismini formatla
        seconds: timeSpent, // Geçirilen süre (saniye)
        pause: timesOfPause, // Duraklama süreleri
      };

      // Geçirilen süre 0'dan büyükse logları sunucuya gönder
      if (timeSpent > 0) {
        UserActivityClient.fetchUserActivity(logs)
          .then((res) => {
            console.log(res); // Başarılı bir yanıt alındığında logla
          })
          .catch((err) => {
            console.log(err); // Hata oluşursa logla
          });
      }

      // Yeni sayfa için başlangıç zamanını güncelle
      startTime.current = new Date();

      // Eski sayfa yolunu güncelle
      setOldPathname(pathname);

      // Yeni sayfa için aktif modül adını güncelle
      setCurrentModule(getCurrentModule());
    };

    calculateTimeSpent(); // Sayfa yüklendiğinde süre hesaplamasını çalıştır

    // Sayfa kapatılmadan önce süreyi hesaplayıp logla
    const handleBeforeUnload = () => calculateTimeSpent();
    window.addEventListener("beforeunload", handleBeforeUnload); // Sayfa kapanmadan önceki event'i dinle

    // Bileşen kaldırıldığında cleanup işlemi
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload); // Event listener'ı kaldırarak bellek sızıntısını önle
    };
  }, [pathname]); // `pathname` değiştiğinde bu useEffect tekrar çalışır

  return (
    <>
      {isProgress ? (
        <ProgressLinear />
      ) : (
        <Router>
          <div
            className="atarev_container"
            data-active={isProgress}
            data-theme={theme}
            ref={bodyRef}
          >
            <Switch>
              <Route exact path="/">
                <ProtectedRoute
                  jwt={cookies.token}
                  component={LandingPage}
                  setPathname={setPathname}
                />
              </Route>
              <Route path="/login">
                <LOGIN />
              </Route>
              <Route path="/home">
                <ProtectedRoute
                  jwt={cookies.token}
                  component={HOME}
                  setPathname={setPathname}
                />
              </Route>
              <Route path="/rules-priority">
                <ProtectedRoute
                  jwt={cookies.token}
                  component={RulesPriority}
                  setPathname={setPathname}
                />
              </Route>
              {routes?.map(
                (route: IRoute) =>
                  !route.disabled && (
                    <Route path={route.path} key={route.path}>
                      <ProtectedRoute
                        jwt={cookies.token}
                        component={route.component}
                        setPathname={setPathname}
                      />
                    </Route>
                  )
              )}
              {(location.hostname.includes("localhost") ||
                location.hostname.includes("msd-dev")) && (
                <>
                  <Route path="/test-page">
                    <ProtectedRoute
                      jwt={cookies.token}
                      component={TestPage}
                      setPathname={setPathname}
                    />
                  </Route>
                  <Route path="/admin-page">
                    <ProtectedRoute
                      jwt={cookies.token}
                      component={AdminPage}
                      setPathname={setPathname}
                    />
                    <AdminPage />
                  </Route>
                </>
              )}
              <Route path="*">
                <Redirect to="/home" />
              </Route>
            </Switch>
          </div>
        </Router>
      )}
    </>
  );
}
