import moment from "moment";
import styles from "./styles.module.css";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { useEffect, useState } from "react";
import HolidaysTooltip from "components/ea/Calendar/Tooltip";

interface ICalendarCard {
  data: any;
  year: number;
  month: number;
  field: string;
  onClick?: (value: number) => void;
  isMonthChange?: boolean;
  onChangeDate: (newDate) => void;
}

const CalendarCard = ({
  data,
  year,
  month,
  field,
  onClick,
  isMonthChange = false,
  onChangeDate,
}: ICalendarCard) => {
  const [monthState, setMonthState] = useState(month);
  const [firstDayOfWeek, setFirstDayOfWeek] = useState(
    new Date(year, month, 1).getDay()
  );
  const [daysInMonth, setDaysInMonth] = useState(
    new Date(year, month + 1, 0).getDate()
  );
  const [monthName, setMonthName] = useState(
    new Date(year, month, 1).toLocaleString("en", {
      month: "long",
    })
  );

  useEffect(() => {
    setMonthState(month);
    setFirstDayOfWeek(new Date(year, month, 1).getDay());
    setDaysInMonth(new Date(year, month + 1, 0).getDate());
    setMonthName(
      new Date(year, month, 1).toLocaleString("en", {
        month: "long",
      })
    );
  }, [data, year, month, field]);

  const emptyDays = [...Array((firstDayOfWeek + 6) % 7)].map((_, index) => (
    <div key={`empty-${index}`} className={styles.day} />
  ));
  const handleChangeMonth = (number: number) => {
    let newMonth = monthState + number;
    if (newMonth >= 0 && newMonth < 12) {
      onChangeDate(moment(new Date(year, newMonth, 1)));
      setMonthState(newMonth);
      setFirstDayOfWeek(new Date(year, newMonth, 1).getDay());
      setDaysInMonth(new Date(year, newMonth + 1, 0).getDate());
      setMonthName(
        new Date(year, newMonth, 1).toLocaleString("en", {
          month: "long",
        })
      );
    }
  };
  // const handleChangePrevMonth = () => {
  //   let newMonth = monthState - 1;
  //   if (newMonth >= 0 && newMonth < 12) {
  //     setMonthState(newMonth);
  //     setFirstDayOfWeek(new Date(year, newMonth, 1).getDay());
  //     setDaysInMonth(new Date(year, newMonth + 1, 0).getDate());
  //     setMonthName(
  //       new Date(year, newMonth, 1).toLocaleString("en", {
  //         month: "long",
  //       })
  //     );
  //   }
  // };
  const getColor = (str: any) => {
    // 1’den fazla Event - #ec4252
    // national – #9c00ed
    // sport – #ff9416
    // conferences – #5504d9
    // school – #00ad92
    // concert - #2fd9fb
    // trade_shows - #d77cbb
    if (str === "national") return "#9c00ed";
    else if (str === "sport") return "#00ad92";
    else if (str === "conferences") return "#5504d9";
    else if (str === "school") return "#ff9416";
    else if (str === "concerts") return "#2fd9fb";
    else if (str === "trade_shows") return "#d77cbb";
    else if (str === "") return "#ec4252";
    return "#fff";
  };
  return (
    <div className={styles.calendar_card}>
      <div className={styles.calendar_month_name_wrapper}>
        <span
          className={styles.calendar_button}
          data-active={isMonthChange}
          onClick={() => handleChangeMonth(-1)}
        >
          <KeyboardArrowLeft />
        </span>
        <span
          className={styles.calendar_month_name}
          onClick={() => onClick && onClick(month)}
        >{`${monthName} ${year}`}</span>
        <span
          className={styles.calendar_button}
          data-active={isMonthChange}
          onClick={() => handleChangeMonth(1)}
        >
          <KeyboardArrowRight />
        </span>
      </div>
      <div className={styles.days_name}>
        <span>M</span>
        <span>T</span>
        <span>W</span>
        <span>T</span>
        <span>F</span>
        <span>S</span>
        <span>S</span>
      </div>
      <div className={styles.days}>
        {emptyDays}
        {[...Array(daysInMonth)].map((_, index) => {
          const day = index + 1;
          const dataOfDay = data
            ? data[
                moment(`${year}-${monthState + 1}-${day}`, "YYYY-MM-DD").format(
                  "YYYY-MM-DD"
                )
              ]
            : null;
          return (
            <HolidaysTooltip
              key={index}
              data={dataOfDay ? dataOfDay["events"] : []}
              other={dataOfDay ? dataOfDay : {}}
            >
              <div
                className={styles.day}
                style={{
                  backgroundColor: `rgba(85, 4, 217, ${
                    dataOfDay ? dataOfDay[field] / 100 : 0
                  })`,
                }}
              >
                {day}
                {dataOfDay &&
                  dataOfDay["events"].some((item) => item?.event_name) && (
                    <span
                      className={styles.dot}
                      style={{
                        backgroundColor: getColor(
                          dataOfDay["events"].length > 1
                            ? ""
                            : dataOfDay["events"][0].type
                        ),
                      }}
                    />
                  )}
              </div>
            </HolidaysTooltip>
          );
        })}
      </div>
    </div>
  );
};

export default CalendarCard;
