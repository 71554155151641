import { ThemeContext } from "context-api/ThemeContext";
import { memo, useContext } from "react";
import DataTable, { createTheme } from "react-data-table-component";

createTheme(
  "custom_atarev_dark",
  {
    text: {
      primary: "rgba(255, 255, 255, 0.6)",
      secondary: "#fff",
    },
    background: {
      default: "transparent",
    },
    context: {
      background: "#cb4b16",
      text: "#FFFFFF",
    },
    divider: {
      default: "transparent",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  },
  "dark"
);
createTheme(
  "custom_atarev_light",
  {
    text: {
      primary: "var(--text-color-primary-light)",
      secondary: "var(--text-color-secondary-light)",
    },
    background: {
      default: "transparent",
    },
    context: {
      background: "#cb4b16",
      text: "var(--text-color-primary-light)",
    },
    divider: {
      default: "transparent",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  },
  "light"
);

interface ITable {
  data: any;
  columns: any;
}

const RecomendedTableComponent = ({ data, columns }: ITable) => {
  const { theme } = useContext(ThemeContext);
  const customStyles = {
    headRow: {
      style: {
        minHeight: "32px",
        background: "transparent",
        borderRadius: 5,
      },
    },
    headCells: {
      style: {
        display: "none",
        height: "32px", // override the row height
        paddingLeft: "16px", // override the cell padding for head cells
        paddingRight: "4px",
        fontFamily: "Fabriga",
        fontSize: 12,
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color:
          theme === "dark"
            ? "rgba(255, 255, 255, 0.6)"
            : "var(--text-color-primary-light)",
      },
    },
    rows: {
      style: {
        height: "48px", // override the row height
        margin: "4px 0 0 0",
        background: "transparent",
        borderRadius: 5,
        border: "none",
        ":nth-child(even)": {
          background: "transparent",
        },
      },
      highlightOnHoverStyle: {
        backgroundColor: "transparent",
        borderRadius: "5px",
      },
    },
    cells: {
      style: {
        paddingLeft: "16px", // override the cell padding for data cells
        paddingRight: "4px",
        fontFamily: "Fabriga",
        fontSize: 14,
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "var(--text-color-secondary)",
      },
    },
    pagination: {
      style: {
        minHeight: "32px",
        color: "var(--text-color-primary)",
      },
    },
  };

  return (
    <DataTable
      theme={theme === "dark" ? "custom_atarev_dark" : "custom_atarev_light"}
      customStyles={customStyles}
      columns={columns}
      data={data}
      selectableRowsNoSelectAll={true} //Tümünü seç butonunu disable eder.
    />
  );
};
export default memo(RecomendedTableComponent);
