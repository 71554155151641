import { useContext, useEffect, useState } from "react";
import { FilterContext } from "context-api/FilterContext";
import { ClevelHeatmapClient } from "lib/api/clevel/heatmap";
import Card from "components/common/Card/Card";
import Skeleton from "components/common/Skeleton";
import PlotlyComponent from "components/common/Charts/Plotly";
import styles from "./styles.module.css";

interface ILowComp {
  filterIsOpen: boolean;
}
export default function ClevelHeatmap({ filterIsOpen }: ILowComp) {
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [variance, setVariance] = useState({ data: [], layout: {} });
  const [mktShr, setMktShr] = useState({ data: [], layout: {} });
  const [avgFare, setAvgFare] = useState({ data: [], layout: {} });

  const { filterList } = useContext(FilterContext);

  useEffect(() => {
    if (!filterIsOpen) {
      setSkeletonLoader(true);
      const params = {
        orig_city_airport: filterList.origCityAirport.join(),
        dest_city_airport: filterList.destCityAirport.join(),
      };
      ClevelHeatmapClient.fetchClevelHeatmapClient(params)
        .then((response: any) => {
          setVariance({
            data: response?.variance_fig?.data ?? [],
            layout: response?.variance_fig?.layout ?? {},
          });
          setMktShr({
            data: response?.mkt_shr_fig?.data ?? [],
            layout: response?.mkt_shr_fig?.layout ?? {},
          });
          setAvgFare({
            data: response?.avg_fare_fig?.data ?? [],
            layout: response?.avg_fare_fig?.layout ?? {},
          });
        })
        .catch(() => {
          setVariance({
            data: [],
            layout: {},
          });
        })
        .finally(() => {
          setTimeout(() => {
            setSkeletonLoader(false);
          }, 2000);
        });
    }
  }, [filterIsOpen, filterList.destCityAirport, filterList.origCityAirport]);

  return (
    <Card variant="secondary" title="Heatmap">
      {skeletonLoader ? (
        <Skeleton width={"100%"} height={616} />
      ) : (
        <Card variant="primary">
          <div className={styles.chart_wrapper}>
            <PlotlyComponent
              height={1600}
              data={variance.data}
              layout={variance.layout}
              margin={{ b: 20, r: 0, l: 0, t: 120, p: 0 }}
            />

            <PlotlyComponent
              height={1600}
              data={mktShr.data}
              layout={mktShr.layout}
              margin={{ b: 20, r: 0, l: 0, t: 120, p: 0 }}
            />

            <PlotlyComponent
              height={1600}
              data={avgFare.data}
              layout={avgFare.layout}
              margin={{ b: 20, r: 0, l: 0, t: 120, p: 0 }}
            />
          </div>
        </Card>
      )}
    </Card>
  );
}
