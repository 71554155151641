import React, { forwardRef } from "react";
import { MdCancel } from "react-icons/md";
import { TableColumn } from "../type";
import styles from "./styles.module.css";
import ColumnFilter from "./getComponentByType";

interface MultiFilterProps {
  allColumns: TableColumn[];
  column: TableColumn;
  filterModel: {
    filterKey: string;
    filterValues: any[];
  }[];
  setFilterModel: React.Dispatch<
    React.SetStateAction<
      {
        filterKey: string;
        filterValues: string[] | number[];
      }[]
    >
  >;
  handleMultiFilter: () => void;
  handleClose: () => void;
}

const MultiFilter = forwardRef<HTMLDivElement, MultiFilterProps>(
  (props, ref) => {
    const {
      allColumns,
      column,
      filterModel,
      setFilterModel,
      handleMultiFilter,
      handleClose,
      ...rest
    } = props;

    const handleFilterKeyOnChange = (e, sm, i) => {
      let newFilterModel = filterModel;
      newFilterModel = newFilterModel.map((item, index) => {
        if (item.filterKey === sm.filterKey && i === index) {
          return { filterKey: e.target.value, filterValues: [] };
        }
        return item;
      });
      setFilterModel(newFilterModel);
    };

    const onFilterChange = (e, sm, i) => {
      let newFilterModel = filterModel;
      newFilterModel = newFilterModel.map((item, index) => {
        if (item.filterKey === sm.filterKey && i === index) {
          return { ...item, filterValues: e };
        }
        return item;
      });
      setFilterModel(newFilterModel);
    };

    const handleRemoveFilterModel = (i) => {
      if (filterModel.length > 0) {
        let newFilterModel = filterModel.filter((_, index) => i !== index);
        setFilterModel(newFilterModel);
      } else {
        handleClose();
      }
    };
    const handleSubmit = (e) => {
      e.preventDefault();
      handleMultiFilter();
    };
    return (
      <div className={styles.container} ref={ref} {...rest}>
        <form action="" onSubmit={handleSubmit}>
          <h4>Filter Menu</h4>
          <div>
            {filterModel.map((sm, index) => {
              return (
                <div className={styles.content} key={index}>
                  <MdCancel
                    size={16}
                    className={styles.filter_remove_icon}
                    onClick={() => handleRemoveFilterModel(index)}
                  />
                  <span>Column</span>
                  <select
                    className={styles.select_col}
                    onChange={(e) => {
                      handleFilterKeyOnChange(e, sm, index);
                    }}
                    value={sm.filterKey}
                  >
                    {allColumns.map((item, index) => (
                      <option key={index} value={item.key}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                  <span>Value</span>

                  <ColumnFilter
                    columnType={
                      allColumns.find((col) => col.key === sm.filterKey)
                        ?.type ?? "text"
                    }
                    allColumns={allColumns}
                    sm={sm}
                    index={index}
                    onFilterChange={onFilterChange}
                  />
                </div>
              );
            })}
          </div>
          <div className={styles.buttons_wrapper}>
            <button
              style={{ marginTop: 8 }}
              type="button"
              onClick={() => {
                // Çoklu filtre eklerken, eklenmiş kolonları kontrol eder ve eklenmemiş olan kolonu sıralamaya ekler.
                let nextFilterItem = allColumns.find((col) =>
                  filterModel.every((model) => col.key !== model.filterKey)
                );
                if (nextFilterItem) {
                  setFilterModel([
                    ...filterModel,
                    { filterKey: nextFilterItem.key, filterValues: [] },
                  ]);
                }
              }}
            >
              Add Filter
            </button>
            <button type="submit" style={{ marginTop: 8 }}>
              Apply
            </button>
          </div>
        </form>
      </div>
    );
  }
);

export default MultiFilter;
