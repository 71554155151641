import { ThemeContext } from "context-api/ThemeContext";
import { useContext } from "react";
import Card from "../Card/Card";
import styles from "./MiniCard.module.css";
import { Tooltip } from "../Tooltip";
import LazyLoad from "react-lazyload";

interface IMiniCard {
  width?: string | number;
  height?: string | number;
  icon: any;
  iconBackgroundColor?: string;
  contentImage?: any;
  title: string;
  total: string;
  borderHoverColor?: string;
}
export default function MiniCard({
  width,
  height,
  icon,
  iconBackgroundColor,
  contentImage,
  title,
  total,
  borderHoverColor,
}: IMiniCard) {
  const { theme } = useContext(ThemeContext);
  return (
    <Card
      variant="tertiary"
      border
      borderRadius={15}
      width={width}
      height={height}
      borderHoverColor={borderHoverColor}
    >
      <div className={styles.container}>
        {iconBackgroundColor ? (
          <div>
            <span
              className={styles.icon_wrapper}
              style={{ backgroundColor: iconBackgroundColor }}
            >
              <LazyLoad height={200} offset={100}>
                <img src={icon} alt="" loading="lazy" />
              </LazyLoad>
            </span>
          </div>
        ) : (
          <img className={styles.icon} src={icon} alt="" />
        )}

        <div className={styles.content_wrapper}>
          <span className={styles.title} data-theme={theme}>
            {title}
          </span>
          <div className={styles.content}>
            <Tooltip title={total || ""} placement={"bottom-start"}>
              <span className={styles.total} data-theme={theme}>
                {total}
              </span>
            </Tooltip>
            {contentImage && <img src={contentImage} alt="" />}
          </div>
        </div>
      </div>
    </Card>
  );
}
