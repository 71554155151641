import Card from "components/common/Card/Card";
import Plotly from "components/common/Charts/Plotly";
import styles from "./styles.module.css";
import Skeleton from "components/common/Skeleton";

interface IPassengersCabinChannelMix {
  passengersMix: {
    data: any[];
    layout: any;
  };
  cabinMix: {
    data: any[];
    layout: any;
  };
  channelMix: {
    data: any[];
    layout: any;
  };
  passengersMixLoader: boolean;
  cabinMixLoader: boolean;
  channelMixLoader: boolean;
  storyText: any;
}

export default function PassengersCabinChannelMix({
  passengersMix,
  cabinMix,
  channelMix,
  passengersMixLoader,
  cabinMixLoader,
  channelMixLoader,
  storyText,
}: IPassengersCabinChannelMix) {
  return (
    <div className={styles.container}>
      <Card
        variant="secondary"
        title={"Passenger Mix"}
        // cardInfo={{
        //   title: storyText?.passenger?.main_card?.title ?? null,
        //   description: storyText?.passenger?.main_card?.content ?? null,
        // }}
        width={"100%"}
      >
        {passengersMixLoader ? (
          <Skeleton width={"100%"} height={450} />
        ) : (
          <Card variant="primary" width={"100%"}>
            <div style={{ height: passengersMix?.layout?.height ?? 450 }}>
              <Plotly
                data={passengersMix.data}
                layout={passengersMix.layout}
                margin={{ l: 40, r: 40, t: 40, b: 40, p: 4 }}
              />
            </div>
          </Card>
        )}
      </Card>
      <Card
        variant="secondary"
        title={"Cabin Mix"}
        // cardInfo={{
        //   title: storyText?.cabin?.main_card?.title ?? null,
        //   description: storyText?.cabin?.main_card?.content ?? null,
        // }}
        width={"100%"}
      >
        {cabinMixLoader ? (
          <Skeleton width={"100%"} height={450} />
        ) : (
          <Card variant="primary" width={"100%"}>
            <div style={{ height: cabinMix?.layout?.height ?? 450 }}>
              <Plotly
                data={cabinMix.data}
                layout={cabinMix.layout}
                margin={{ l: 40, r: 40, t: 40, b: 40, p: 4 }}
              />
            </div>
          </Card>
        )}
      </Card>
      <Card
        variant="secondary"
        title={"Channel Mix"}
        // cardInfo={{
        //   title: storyText?.channel?.main_card?.title ?? null,
        //   description: storyText?.channel?.main_card?.content ?? null,
        // }}
        width={"100%"}
      >
        {channelMixLoader ? (
          <Skeleton width={"100%"} height={450} />
        ) : (
          <Card variant="primary" width={"100%"}>
            <div style={{ height: channelMix?.layout?.height ?? 450 }}>
              <Plotly
                data={channelMix.data}
                layout={channelMix.layout}
                margin={{ l: 40, r: 40, t: 40, b: 40, p: 4 }}
              />
            </div>
          </Card>
        )}
      </Card>
    </div>
  );
}
