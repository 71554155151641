import { useState } from "react";
import Layout from "components/common/Layout/Layout";
import KPIMini from "components/msd/KPIMini/KPIMini";
import ClevelBookingGrowth from "components/clevel/BokingGrowth";

export default function BOOKINGGROWTH() {
  const [filterIsOpen, setFilterIsOpen] = useState(true);

  return (
    <Layout
      setFilterIsOpen={setFilterIsOpen}
      filterIsOpen={filterIsOpen}
      title="C-Level Fare Structure"
    >
      <KPIMini filterIsOpen={filterIsOpen} />
      <ClevelBookingGrowth filterIsOpen={filterIsOpen} />
    </Layout>
  );
}
