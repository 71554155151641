import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import styles from "./styles.module.css";
import { Tooltip } from "../Tooltip";

interface ICardInfo {
  title?: string | null;
  description?: string | JSX.Element | null;
  visible?: boolean;
}
const Content = ({ title, description }) => {
  const defaultTitle = "Lorem";
  const defaultDescription =
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione, nostrum sapiente praesentium obcaecati quia minima quae asperiores quibusdam error odit mollitia ex voluptas laboriosam distinctio aspernatur tenetur. Beatae, voluptates autem.";
  return (
    <div className={styles.content}>
      <span className={styles.title}>
        {title !== "" ? title ?? defaultTitle : defaultTitle}
      </span>
      <span className={styles.description}>
        {description !== ""
          ? description ?? defaultDescription
          : defaultDescription}
      </span>
    </div>
  );
};
export default function CardInfo({
  title,
  description,
  visible = false,
}: ICardInfo) {
  if (!visible) {
    return (
      <>
        <Tooltip
          title={<Content title={title} description={description} />}
          arrow
        >
          <InfoOutlinedIcon sx={{ marginLeft: 1 }} />
        </Tooltip>
      </>
    );
  }
  return <></>;
}
