import { IFilterContext } from "types/type";
import { QueryParameterStringBuilder } from "lib/query-parameter-string-builder";
import { RestClient } from "../../RestClient";

interface IProductOverviewPaxMix {
  filterList: IFilterContext;
  agg_view: string;
  selected_yearmonth: string;
  dark_theme: boolean;
}

export class ProductOverviewPaxMixClient {
  private static _client: RestClient;

  static fetchProductOverviewPaxMix({
    filterList,
    agg_view,
    selected_yearmonth,
    dark_theme,
  }: IProductOverviewPaxMix) {
    const qp = QueryParameterStringBuilder.apply(filterList);
    return ProductOverviewPaxMixClient.getClient().getCall(
      `/api/msdv2/bookings/pax-mix?${qp}&agg_view=${agg_view}&selected_yearmonth=${selected_yearmonth}&dark_theme=${dark_theme}`
    );
  }

  static getClient() {
    if (ProductOverviewPaxMixClient._client == null) {
      ProductOverviewPaxMixClient._client = new RestClient();
    }
    return ProductOverviewPaxMixClient._client;
  }
}
