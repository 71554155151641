import { IFilterContext } from "types/type";
import { QueryParameterStringBuilder } from "lib/query-parameter-string-builder";
import { RestClient } from "../../RestClient";

interface IAgencyAnalysisAgencyQuadrant {
  filterList: IFilterContext;
  selected_carrier: string;
  comp_type: string;
  time_direction: string;
  selected_yearmonth: string;
  dark_theme: boolean;
}
export class AgencyAnalysisAgencyQuadrantClient {
  private static _client: RestClient;

  static fetchAgencyAnalysisAgencyQuadrant({
    filterList,
    selected_carrier,
    comp_type,
    time_direction,
    selected_yearmonth,
    dark_theme,
  }: IAgencyAnalysisAgencyQuadrant) {
    const qp = QueryParameterStringBuilder.apply(filterList);
    return AgencyAnalysisAgencyQuadrantClient.getClient().getCall(
      `/api/msdv2/agency-analysis/agency-quadrant?selected_carrier=${selected_carrier}&comp_type=${comp_type}&time_direction=${time_direction}&selected_yearmonth=${selected_yearmonth}&${qp}&dark_theme=${dark_theme}`
    );
  }

  static getClient() {
    if (AgencyAnalysisAgencyQuadrantClient._client == null) {
      AgencyAnalysisAgencyQuadrantClient._client = new RestClient();
    }
    return AgencyAnalysisAgencyQuadrantClient._client;
  }
}
