/* eslint-disable react-hooks/exhaustive-deps */
import {
  AttachMoney,
  CalendarMonth,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import Card from "components/common/Card/Card";
import moment from "moment";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./LovestFareCalendar.module.css";
import WeekComponent from "./Week";
import DayComponent from "./Day";
import { ThemeContext } from "context-api/ThemeContext";
import Dropdown from "components/common/Dropdown/Dropdown";
import { useOutsideClick } from "hooks/useOutsideClick";
import Calendar from "./Month";
import { useFullScreenListener } from "hooks/useFullScreenListener";
import { Fullscreen, FullscreenExit } from "@mui/icons-material";
import images from "constans/images";
import { useQuery } from "hooks/useQuery";
import { FlightTypeSwitchContext } from "context-api/OwRtContext";
import DatePicker from "components/common/DatePicker/DatepickerLastest";
import BasicFilter from "../BasicFilter";

interface IFares {
  weekDay: string;
  day: number;
  fare: string;
  hoverTexts: {
    country_name: string;
    end_date: string;
    holiday_name: string;
    start_date: string;
  }[];
  soldOut: boolean;
  currency: string;
  date: string;
  is_holiday: boolean;
  holidays: [
    {
      country_name: string;
      holiday_name: string;
      start_date: string;
      end_date: string;
    }
  ];
  setSelectedDate: Dispatch<SetStateAction<Date | null>>;
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
}

export default function LovestFareCalendar({
  filterIsOpen,
  setVisible,
  setSelectedDate,
  activeStep,
  setActiveStep,
}) {
  const calendar_type = localStorage.getItem("calendar_type");
  const [added, setAdded] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectOptions] = useState(["Day", "Week", "Month"]);
  const [selectedOption, setSelectedOption] = useState<
    "Day" | "Week" | "Month"
  >(calendar_type ? JSON.parse(calendar_type) : "Month");
  const [dropdownOptions, setDropdownOptions] = useState<string[]>([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [fares, setFares] = useState<IFares[]>([]);
  const [filterArrowButton, setFilterArrowButton] = useState<string | null>(
    null
  );
  const [date, setDate] = useState(new Date());
  const [datePickerModal, setDatePickerModal] = useState(false);
  const [dateFormat, setDateFormat] = useState("MM YYYY");
  const [dateRange, setDateRange] = useState({
    start: new Date(),
    end: new Date(),
  });

  const { theme } = useContext(ThemeContext);
  const {
    flightTypeSwitch,
    allFlightType,
    owRtSwitch,
    setOwRtSwitch,
    cs,
    setCs,
  } = useContext(FlightTypeSwitchContext);

  const wrapperRef: any = useRef(null);
  const wrapperRefDatePicker: any = useRef(null);

  useFullScreenListener(() =>
    setIsFullScreen(document.fullscreenElement !== null)
  );

  useOutsideClick(wrapperRef, () => setIsOpen(false));
  useOutsideClick(wrapperRefDatePicker, () => setDatePickerModal(false));
  let query = useQuery();

  useEffect(() => {
    const queryView = query.get("view");
    const queryDate = query.get("date");
    const queryCabin = query.get("cabin");
    if (queryView && queryDate) {
      if (
        selectOptions?.some(
          (el) => el?.toLocaleLowerCase() === queryView?.toLocaleLowerCase()
        )
      ) {
        let newView: any =
          queryView.charAt(0).toUpperCase() + queryView.slice(1);
        if (queryCabin === "Economy") {
          setActiveStep(169);
        }
        if (queryCabin === "Business") {
          setActiveStep(85);
        }
        if (queryCabin === "FIRST") {
          setActiveStep(0);
        }
        setSelectedOption(newView);
        // localStorage.setItem("calendar_type", newView);
        setDate(new Date(queryDate));
      }
    }
  }, []);

  useEffect(() => {
    if (selectedOption === "Day") {
      setDateFormat("DD MMM YYYY");
    }
    if (selectedOption === "Week") {
      setDateFormat("DD MMM YYYY");
    }
    if (selectedOption === "Month") {
      setDateFormat("MMM YYYY");
    }
  }, [selectedOption]);

  useEffect(() => {
    setSelectedDate(date);
  }, [date]);

  useEffect(() => {
    if (dropdownOptions.length === 0) {
      if (added.length !== 0) setAdded([]);
    }
  }, [dropdownOptions]);

  function toggleFullScreen() {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      const selectedElement = document.querySelector(`.calendar-full-screen`);
      selectedElement?.requestFullscreen();
    }
  }

  return (
    <Card variant="secondary">
      <div
        className={`${styles.container} calendar-full-screen`}
        style={{
          backgroundImage: isFullScreen ? "var(--bg-image)" : "none",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className={styles.fullscreen_button_wrapper}>
          <span
            onClick={() => {
              setIsFullScreen(!isFullScreen);
              toggleFullScreen();
            }}
            className={styles.fullscreen_button}
          >
            {isFullScreen ? <FullscreenExit /> : <Fullscreen />}
          </span>
        </div>
        <div className={styles.card_header}>
          <div className={styles.left_filter}>
            <div
              className={styles.date_picker_wrapper}
              ref={wrapperRefDatePicker}
            >
              <div
                className={styles.date_picker_icon}
                onClick={() => setDatePickerModal(true)}
                data-theme={theme}
              >
                <CalendarMonth />
              </div>
              <div
                className={styles.date_picker_modal}
                data-active={datePickerModal}
              >
                <Card variant="secondary">
                  <Card variant="primary">
                    <DatePicker
                      value={date}
                      startDate={date}
                      minDate={moment().startOf("day").toDate()} // Bugünden önceki tarihler seçilemesin
                      onChangeDate={(date) => {
                        setDate(date);
                      }}
                    />
                  </Card>
                </Card>
              </div>
            </div>
            <div
              className={styles.card_header_select_options}
              data-theme={theme}
              ref={wrapperRef}
            >
              <div
                className={styles.select_options}
                onClick={() => setIsOpen(true)}
              >
                <>
                  <span>{selectedOption}</span>
                  <KeyboardArrowDown className={styles.icon} />
                </>
              </div>
              <div
                className={styles.open_menu}
                data-active={isOpen}
                data-theme={theme}
              >
                {selectOptions?.map((item: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className={styles.open_menu_item}
                      data-theme={theme}
                      onClick={() => {
                        setSelectedOption(item);
                        setIsOpen(false);
                        localStorage.setItem(
                          "calendar_type",
                          JSON.stringify(item)
                        );
                      }}
                    >
                      <span>{item}</span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              className={styles.today_button}
              data-theme={theme}
              data-active={
                moment(new Date()).format("DD-MM-YYYY") ===
                moment(date).format("DD-MM-YYYY")
              }
              onClick={() => {
                if (
                  !(
                    moment(new Date()).format("DD-MM-YYYY") ===
                    moment(date).format("DD-MM-YYYY")
                  )
                ) {
                  setDate(new Date());
                }
              }}
            >
              Today
            </div>
            <div className={styles.card_header_arrows}>
              <span
                className={`${styles.arrow_left} ${
                  moment(date).isSameOrAfter(moment(new Date()))
                    ? ""
                    : styles.arrow_left_disabled
                }`}
                data-theme={theme}
                onClick={() => {
                  const newDate =
                    selectedOption === "Day"
                      ? moment(date).subtract(1, "days").startOf("day")
                      : selectedOption === "Week"
                      ? moment(date).subtract(1, "weeks").startOf("day")
                      : moment(date).subtract(1, "months").startOf("day");

                  const today = moment().startOf("day");

                  // Eğer yeni tarih bugünden eski değilse setDate'i çalıştır
                  if (newDate.isSameOrAfter(today)) {
                    setDate(newDate.toDate());
                  }
                }}
              >
                <KeyboardArrowLeft />
              </span>
              <span
                className={styles.arrow_right}
                data-theme={theme}
                onClick={() => {
                  const newDate =
                    selectedOption === "Day"
                      ? moment(date).add(1, "days").format()
                      : selectedOption === "Week"
                      ? moment(date).add(1, "weeks").format()
                      : moment(date).add(1, "M").format();
                  setDate(new Date(newDate));
                }}
              >
                <KeyboardArrowRight />
              </span>
            </div>
            <div className={styles.card_header_date_text}>
              <span>
                {selectedOption === "Day" && moment(date).format(dateFormat)}
                {selectedOption === "Week" &&
                  `${moment(dateRange.start).format("DD MMM")} - ${moment(
                    dateRange.end
                  ).format(dateFormat)}`}
                {selectedOption === "Month" && moment(date).format(dateFormat)}
              </span>
            </div>
            <div
              className={styles.arrow_filter_wrapper}
              data-visible={selectedOption === "Month"}
            >
              <span
                className={styles.filter_button}
                data-active={filterArrowButton === "up"}
                onClick={() => setFilterArrowButton("up")}
              >
                <img src={images.arrow_up} alt="" />
              </span>
              <span
                className={styles.filter_button}
                data-active={filterArrowButton === "down"}
                onClick={() => setFilterArrowButton("down")}
              >
                <img src={images.arrow_down} alt="" />
              </span>
              <span
                className={styles.filter_button}
                data-active={filterArrowButton === null}
                onClick={() => setFilterArrowButton(null)}
              >
                <img src={images.arrow_up} alt="" />
                <img src={images.arrow_down} alt="" />
              </span>
            </div>
            <div
              className={styles.modal_button}
              onClick={() => setVisible(true)}
            >
              <AttachMoney style={{ width: 18 }} />
            </div>
            <div className={styles.rt_ow_switch_wrapper}>
              <span
                data-active={owRtSwitch === "rt"}
                data-select={
                  allFlightType && allFlightType !== "rt" && !flightTypeSwitch
                }
                onClick={() => {
                  flightTypeSwitch && setOwRtSwitch("rt");
                }}
              >
                RT
              </span>
              <span
                data-active={owRtSwitch === "ow"}
                data-select={
                  allFlightType && allFlightType !== "ow" && !flightTypeSwitch
                }
                onClick={() => {
                  flightTypeSwitch && setOwRtSwitch("ow");
                }}
              >
                OW
              </span>
              <span
                data-active={cs}
                onClick={() => {
                  setCs(!cs);
                }}
              >
                CS
              </span>
            </div>
          </div>
          <div
            className={styles.holiday_wrapper}
            data-holiday={
              selectedOption !== "Month" &&
              fares?.some(
                (el) =>
                  moment(date).format("YYYY-MM-DD") === el?.date &&
                  el?.is_holiday
              )
            }
          >
            <img
              src={
                theme === "light"
                  ? images.lowest_info_light
                  : images.lowest_info
              }
              alt=""
            />
            <span>
              {fares
                ?.filter(
                  (el) =>
                    moment(date).format("YYYY-MM-DD") === el?.date &&
                    el?.is_holiday
                )[0]
                ?.holidays?.map((element) => (
                  <p>{element?.holiday_name}</p>
                ))}
            </span>
          </div>

          <div style={{ display: "flex" }}>
            {dropdownOptions.length > 0 && (
              <div
                style={{
                  width: 150,
                  display: selectedOption === "Month" ? "block" : "none",
                  position: "relative",
                  top: -6,
                }}
              >
                <Dropdown
                  data={dropdownOptions}
                  added={added}
                  setAdded={setAdded}
                  singleSelected
                />
              </div>
            )}
            <div className={styles.header_button_wrapper} data-theme={theme}>
              <span
                className={styles.button}
                onClick={() => setActiveStep(0)}
                data-active={activeStep === 0}
                data-theme={theme}
              >
                First
              </span>
              <span
                className={styles.button}
                onClick={() => setActiveStep(85)}
                data-active={activeStep === 85}
                data-theme={theme}
              >
                Bus
              </span>
              <span
                className={styles.button}
                onClick={() => setActiveStep(169)}
                data-active={activeStep === 169}
                data-theme={theme}
              >
                Eco
              </span>
              <span
                className={styles.active_button}
                style={{ left: activeStep }}
              ></span>
            </div>
          </div>
        </div>
        <div>
          <BasicFilter />
        </div>
        {selectedOption === "Day" && (
          <div className={styles.card_body}>
            <DayComponent
              selectedDate={date}
              setDate={setDate}
              cabin_code={
                activeStep === 0 ? "FIRST" : activeStep === 85 ? "BUS" : "ECO"
              }
              setFares={setFares}
              filterIsOpen={filterIsOpen}
            />
          </div>
        )}
        {selectedOption === "Week" && (
          <div className={styles.card_body}>
            <WeekComponent
              setDateRange={setDateRange}
              selectedDate={date}
              setDate={setDate}
              cabin_code={
                activeStep === 0 ? "FIRST" : activeStep === 85 ? "BUS" : "ECO"
              }
              selectedCarrier={added}
              setDropdownOptions={setDropdownOptions}
              filterIsOpen={filterIsOpen}
            />
          </div>
        )}
        {selectedOption === "Month" && (
          <div className={styles.card_body}>
            <Calendar
              selectedDate={date}
              filterIsOpen={filterIsOpen}
              cabin_code={
                activeStep === 0 ? "FIRST" : activeStep === 85 ? "BUS" : "ECO"
              }
              selectedCarrier={added}
              setDropdownOptions={setDropdownOptions}
              selectedFilter={filterArrowButton}
              setSelectedOption={setSelectedOption}
              setDate={setDate}
            />
          </div>
        )}
      </div>
    </Card>
  );
}
