import React, { useContext, useEffect, useState } from "react";
import Card from "components/common/Card/Card";
import { FilterContext } from "context-api/FilterContext";
import { FareStructureClient } from "lib/api/lfa/lowest-fare-calendar/FareStructure";
import styles from "./styles.module.css";
import images from "constans/images";

import moment from "moment";
import Ancillaries from "../Ancillaries";
import { UserProfileContext } from "context-api/UserProfile";
import Table from "components/common/Table/newTable";
import { FareStructureFilterOptionsClient } from "lib/api/lfa/lowest-fare-calendar/FareStructureFilterOptions";
import OpenMenu from "./OpenMenu";

const FareStructureTable = ({
  visible,
  setVisible,
  selectedDate,
  cabin_code,
  filterIsOpen,
}) => {
  const { filterList } = useContext(FilterContext);
  const { user } = useContext(UserProfileContext);
  const [data, setData] = useState<{ data: any[]; columns: any[] }>({
    data: [],
    columns: [],
  });
  const [switchButton, setSwitchButton] = useState(false);
  const [loader, setLoader] = useState(true);

  const [meta, setMeta] = useState<{
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  }>({
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 0,
    totalPages: 1,
  });
  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    pageSize: 10,
  });
  const [sortModel, setSortModel] = useState<
    { sortKey: string; sortOrder: "asc" | "desc" }[]
  >([]);

  const [filterOptions, setFilterOptions] = useState<any[]>(["cabin"]);
  const [filterSelections, setFilterSelections] = useState<
    { key: string; value: string[] }[]
  >([]);

  useEffect(() => {
    const params = {};
    // Geçici süre kapatıldı
    if (false) {
      FareStructureFilterOptionsClient.fetchFareStructureFilterOptions({
        params,
      })
        .then((res: any) => {
          console.log(res);
          setFilterOptions(res);
        })
        .catch((err) => console.log(err));
    }
  }, []);

  useEffect(() => {
    if (!filterIsOpen && selectedDate && visible) {
      setLoader(true);
      const getMonth = selectedDate.getMonth(); // getMonth parametresi ile seçilmiş tarihte hangi ayda olduğumuzu buluyorum
      const getFullYear = selectedDate.getFullYear(); // getFullYear parametresi ile seçilmiş tarihte hangi yılda olduğumuzu buluyorum
      const dayOfMonth = new Date(getFullYear, getMonth + 1, 0).getDate();
      const firstDayCurrentMonth = new Date(getFullYear, getMonth, 1); // seçilmiş tarihin ilk gününü buluyorum. 04.11.2022 tarihinin ilk günü 01.11.2022 gibi
      const endDayCurrentMonth = new Date(getFullYear, getMonth, dayOfMonth); // seçilmiş tarihin son gününü buluyorum. 04.11.2022 tarihinin son günü 30.11.2022 gibi
      const params = {
        orig_city_airport: filterList.origCityAirport.join(),
        dest_city_airport: filterList.destCityAirport.join(),
        cabin: cabin_code ?? filterList.cabin.join(),
        date_range_start: moment(firstDayCurrentMonth).format("YYYY-MM-DD"),
        date_range_end: moment(endDayCurrentMonth).format("YYYY-MM-DD"),
        page_: paginationModel.page,
        limit_: paginationModel.pageSize,
        ...(sortModel &&
          sortModel?.length > 0 && { sort_: JSON.stringify(sortModel) }), // sort_ varsa ekle
        ...(filterSelections &&
          filterSelections?.length > 0 && {
            filter_: JSON.stringify(filterSelections),
          }), // filter_ varsa ekle
      };
      FareStructureClient.fetchFareStructure({ params })
        .then((res: any) => {
          setData({
            data: res?.table,
            columns: getColumns(res.labels),
          });
          setMeta(res?.meta);
        })
        .catch((err) => console.log(err))
        .finally(() => setLoader(false));
    }
  }, [
    filterList,
    selectedDate,
    cabin_code,
    filterIsOpen,
    visible,
    paginationModel,
    sortModel,
    filterSelections,
  ]);

  const getColumns = (labels) => {
    const array: {
      title: string;
      key: string;
      cell: (row: any) => JSX.Element;
      width?: string | number;
      sortable?: boolean;
    }[] = [];
    for (const [key, title] of Object.entries(labels)) {
      const obj: any = {
        title,
        key,
        cell: (row) => {
          return <span className={"rdb-custom-cell"}>{row[key]}</span>;
        },
        sortable: true,
      };
      array.push(obj);
    }
    return array;
  };
  return (
    <div
      style={{
        minWidth: 500,
        maxWidth: 720,
        backgroundColor: "rgb(17, 12, 32)",
        borderRadius: 12,
      }}
    >
      <Card variant="secondary">
        <div className={styles.close_button_wrapper}>
          <span
            className={styles.switch_wrapper}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <span
              className={styles.switch_button}
              data-active={!switchButton}
              onClick={() => {
                setSwitchButton(false);
              }}
            >
              Table
            </span>
            <span
              className={styles.switch_button}
              style={{
                cursor: user?.username !== "demo" ? "no-drop" : "pointer",
              }}
              data-active={switchButton}
              onClick={() => {
                if (user?.username === "demo") setSwitchButton(true);
              }}
            >
              Ancillaries
            </span>
          </span>
          {false && (
            <div style={{ margin: "0 0 0 16px" }}>
              <OpenMenu
                key="Test"
                options={filterOptions}
                selections={filterSelections}
                setSelections={setFilterSelections}
              />
            </div>
          )}
          <span
            className={styles.close_button}
            onClick={(e) => {
              e.stopPropagation();
              setVisible(false);
            }}
          >
            <img src={images.cancel_red} alt="" />
          </span>
        </div>

        {switchButton ? (
          <Ancillaries />
        ) : (
          <Table
            columns={data.columns}
            data={data.data}
            pagination
            paginationModel={meta}
            loading={loader}
            serverMode="server"
            defaultPageSize={10}
            tableHeight="500px"
            onColumnOrderChange={(newColumnOrder) => {
              console.log(
                "Yeni Kolon Sıralaması:",
                newColumnOrder.map((col) => col.key)
              );
            }}
            onPaginationModelChange={(newPaginationModel) => {
              // fetch data from server
              setPaginationModel(newPaginationModel);
            }}
            onSortModelChange={(newSortModel) => {
              // fetch data from server
              setSortModel(newSortModel);
            }}
            onFilterModelChange={(newFilterModel) => {
              // fetch data from server
            }}
          />
        )}
      </Card>
    </div>
  );
};

export default FareStructureTable;
