import { RestClient } from "../../RestClient";

export class HolidaysTableClient {
  private static _client: RestClient;

  static fetchHolidaysTable(params) {
    return HolidaysTableClient.getClient().getCall(
      `/api/msdv2/events/table?${new URLSearchParams(params)}`
    );
  }

  static getClient() {
    if (HolidaysTableClient._client == null) {
      HolidaysTableClient._client = new RestClient();
    }
    return HolidaysTableClient._client;
  }
}
