/* eslint-disable react-hooks/exhaustive-deps */

import Button from "components/common/Button/Button";
import Card from "components/common/Card/Card";
import Dropdown from "components/common/Dropdown/Dropdown";
import Notification from "components/common/Notification/Notification";
import images from "constans/images";
import { FilterContext } from "context-api/FilterContext";
import { FilterClient } from "lib/api/filter";
import { useContext, useEffect, useState } from "react";
import styles from "./DynamicFilterOpened.module.css";

interface IDynamicFilter {
  setIsOpen: any;
  getCarriers: any;
}
export default function DynamicFilter({
  setIsOpen,
  getCarriers,
}: IDynamicFilter) {
  const [origRegionDropdown, setOrigRegionDropdown] = useState([]);
  const [destRegionDropdown, setDestRegionDropdown] = useState([]);
  const [origCountryDropdown, setOrigCountryDropdown] = useState([]);
  const [destCountryDropdown, setDestCountryDropdown] = useState([]);
  const [origCityAirportDropdown, setOrigCityAirportDropdown] = useState([]);
  const [destCityAirportDropdown, setDestCityAirportDropdown] = useState([]);
  const [posDropdown, setPosDropdown] = useState([]);
  const [mainCompetitorDropdown, setMainCompetitorDropdown] = useState([]);
  const [selectedCompetitorsDropdown, setSelectedCompetitorsDropdown] =
    useState([]);
  const [salesChannelDropdown, setSalesChannelDropdown] = useState([]);
  const [cabinDropdown, setCabinDropdown] = useState([]);
  const [paxTypeDropdown, setPaxTypeDropdown] = useState([]);
  const { filterList, setFilterList } = useContext(FilterContext);
  const filterListParams = [
    "origRegion",
    "destRegion",
    "origCountry",
    "destCountry",
    "origCityAirport",
    "destCityAirport",
    "pos",
    "mainCompetitor",
    "selectedCompetitors",
    "salesChannel",
    "cabin",
    "paxType",
  ];
  const module = localStorage.getItem("module");

  const handleAcceptFilter = () => {
    setIsOpen(false);
    let user = localStorage.getItem("user");
    if (user) {
      let parsedUserData = {
        ...JSON.parse(user),
        defaultFilterSelection: filterList,
      };
      localStorage.setItem("user", JSON.stringify(parsedUserData));
    }
  };

  const clearFilter = () => {
    filterListParams.forEach((param) => {
      setFilterList((prevState) => ({
        ...prevState,
        [param]: [],
      }));
    });
  };

  useEffect(() => {
    getDropdown();
  }, [filterList]);

  const getDropdown = () => {
    FilterClient.fetchFilterOptions(filterList)
      .then((result: any) => {
        setOrigRegionDropdown(result.orig_region);
        setOrigCountryDropdown(result.orig_country);
        setOrigCityAirportDropdown(result.orig_city_airport);
        setDestRegionDropdown(result.dest_region);
        setDestCountryDropdown(result.dest_country);
        setDestCityAirportDropdown(result.dest_city_airport);
        setPosDropdown(result.pos);
        setMainCompetitorDropdown(result.main_competitor);
        setSelectedCompetitorsDropdown(result.selected_competitors);
        setSalesChannelDropdown(result.sales_channel);
        setCabinDropdown(result.cabin);
        setPaxTypeDropdown(result.pax_type);
        localStorage.setItem(
          "allCompetitors",
          JSON.stringify(result.main_competitor)
        );
      })
      .catch(() => {
        Notification({
          type: "error",
          message: "Filter data could not be loaded!",
        });
      });
  };

  const HandleFilterListSelectedChange = ({ param, item }) => {
    if (item[item.length - 1] === "All") {
      setFilterList((prevState) => ({ ...prevState, [param]: ["All"] }));
    } else {
      const other = item.filter((element) => element !== "All");
      setFilterList((prevState) => ({ ...prevState, [param]: other }));
    }
  };

  const handleChangeFromTo = (data) => {
    setFilterList((prevState) => ({
      ...prevState,
      [data.from]: data.toData,
    }));
    setFilterList((prevState) => ({
      ...prevState,
      [data.to]: data.fromData,
    }));
  };

  return (
    <div className={styles.container}>
      <Card variant="secondary" width={1080} height={620} zIndex={3}>
        <div className={styles.head}>
          <span className={styles.edit_filter_text}>Edit Filters</span>
          <div className={styles.head_buttons_wrapper}>
            <div className={styles.clear_button} onClick={() => clearFilter()}>
              Clear
            </div>
            <div
              className={styles.tick_button_wrapper}
              onClick={() => {
                if (
                  filterList.mainCompetitor.length > 0 &&
                  filterList.selectedCompetitors.length > 0
                ) {
                  handleAcceptFilter();
                }
              }}
            >
              <img
                src={
                  filterList.mainCompetitor.length > 0 &&
                  filterList.selectedCompetitors.length > 0
                    ? images.tick_green
                    : images.cancel_red
                }
                alt=""
              />
            </div>
          </div>
        </div>
        <div className={styles.body}>
          <Card
            variant="primary"
            title="Region"
            icon={images.navigation_maps}
            iconSize="sm"
            width={252}
            height={252}
            zIndex={6}
          >
            <div className={styles.filter_card}>
              <p className={styles.from_text}>From:</p>
              <Dropdown
                data={origRegionDropdown}
                added={filterList.origRegion}
                setAdded={(item) => {
                  HandleFilterListSelectedChange({ param: "origRegion", item });
                }}
                disabled={
                  filterList.origCountry.length > 0 ||
                  filterList.origCityAirport.length > 0
                }
                getCarriers={getCarriers}
                singleSelected={module === "lfa"}
              />
              <div
                className={styles.change_from_to_arrow_wrapper}
                onClick={() =>
                  handleChangeFromTo({
                    from: "origRegion",
                    fromData: filterList.origRegion,
                    to: "destRegion",
                    toData: filterList.destRegion,
                  })
                }
              >
                <img src={images.arrow_change} alt="" width={24} height={24} />
              </div>
              <p className={styles.to_text}>To:</p>
              <Dropdown
                data={destRegionDropdown}
                added={filterList.destRegion}
                setAdded={(item) => {
                  HandleFilterListSelectedChange({ param: "destRegion", item });
                }}
                disabled={
                  (filterList.origRegion.length < 1 &&
                    filterList.origCountry.length < 1 &&
                    filterList.origCityAirport.length < 1) ||
                  filterList.destCityAirport.length > 0 ||
                  filterList.destCountry.length > 0
                }
                getCarriers={getCarriers}
                singleSelected={module === "lfa"}
              />
            </div>
          </Card>
          <Card
            variant="primary"
            title="Country"
            icon={images.flag}
            iconSize="sm"
            width={398}
            height={252}
            zIndex={6}
          >
            <div className={styles.filter_card}>
              <p className={styles.from_text}>From:</p>
              <Dropdown
                data={origCountryDropdown}
                added={filterList.origCountry}
                setAdded={(item) => {
                  HandleFilterListSelectedChange({
                    param: "origCountry",
                    item,
                  });
                }}
                disabled={
                  filterList.origRegion.length > 0 ||
                  filterList.origCityAirport.length > 0
                }
                getCarriers={getCarriers}
                singleSelected={module === "lfa"}
              />
              <div
                className={styles.change_from_to_arrow_wrapper}
                onClick={() =>
                  handleChangeFromTo({
                    from: "origCountry",
                    fromData: filterList.origCountry,
                    to: "destCountry",
                    toData: filterList.destCountry,
                  })
                }
              >
                <img src={images.arrow_change} alt="" width={24} height={24} />
              </div>
              <p className={styles.to_text}>To:</p>
              <Dropdown
                data={destCountryDropdown}
                added={filterList.destCountry}
                setAdded={(item) => {
                  HandleFilterListSelectedChange({
                    param: "destCountry",
                    item,
                  });
                }}
                disabled={
                  (filterList.origRegion.length < 1 &&
                    filterList.origCountry.length < 1 &&
                    filterList.origCityAirport.length < 1) ||
                  filterList.destRegion.length > 0 ||
                  filterList.destCityAirport.length > 0
                }
                getCarriers={getCarriers}
                singleSelected={module === "lfa"}
              />
            </div>
          </Card>
          <Card
            variant="primary"
            title="City / Airport"
            icon={images.travel_plane_take_off}
            iconSize="sm"
            width={398}
            height={252}
            zIndex={6}
          >
            <div className={styles.filter_card}>
              <p className={styles.from_text}>From:</p>
              <Dropdown
                data={origCityAirportDropdown}
                added={filterList.origCityAirport}
                setAdded={(item) => {
                  HandleFilterListSelectedChange({
                    param: "origCityAirport",
                    item,
                  });
                }}
                disabled={
                  filterList.origRegion.length > 0 ||
                  filterList.origCountry.length > 0
                }
                getCarriers={getCarriers}
                singleSelected={module === "lfa"}
              />
              <div
                className={styles.change_from_to_arrow_wrapper}
                onClick={() =>
                  handleChangeFromTo({
                    from: "origCityAirport",
                    fromData: filterList.origCityAirport,
                    to: "destCityAirport",
                    toData: filterList.destCityAirport,
                  })
                }
              >
                <img src={images.arrow_change} alt="" width={24} height={24} />
              </div>
              <p className={styles.to_text}>To:</p>
              <Dropdown
                data={destCityAirportDropdown}
                added={filterList.destCityAirport}
                setAdded={(item) => {
                  HandleFilterListSelectedChange({
                    param: "destCityAirport",
                    item,
                  });
                }}
                disabled={
                  (filterList.origRegion.length < 1 &&
                    filterList.origCountry.length < 1 &&
                    filterList.origCityAirport.length < 1) ||
                  filterList.destRegion.length > 0 ||
                  filterList.destCountry.length > 0
                }
                getCarriers={getCarriers}
                singleSelected={module === "lfa"}
              />
            </div>
          </Card>
        </div>
        <div className={styles.body}>
          <Card
            variant="primary"
            title="Point of Sale"
            titleMargin="24px 16px 20px 16px"
            icon={images.travel_map_point}
            iconSize="sm"
            width={528}
            height={132}
            zIndex={5}
          >
            <div className={styles.filter_card}>
              <Dropdown
                data={posDropdown}
                added={filterList.pos}
                setAdded={(item) => {
                  if (
                    filterList.destRegion.length > 0 ||
                    filterList.destCountry.length > 0 ||
                    (filterList.destCityAirport.length > 0 && item.length === 0)
                  ) {
                    HandleFilterListSelectedChange({
                      param: "pos",
                      item: ["All"],
                    });
                    return;
                  }
                  HandleFilterListSelectedChange({
                    param: "pos",
                    item,
                  });
                }}
                disabled={
                  filterList.destRegion.length < 1 &&
                  filterList.destCountry.length < 1 &&
                  filterList.destCityAirport.length < 1
                }
                getCarriers={getCarriers}
              />
            </div>
          </Card>
          <Card
            variant="primary"
            title="Competitor"
            titleMargin="24px 16px 20px 16px"
            icon={images.chess}
            iconSize="sm"
            width={528}
            height={132}
            zIndex={5}
          >
            <div className={styles.filter_card}>
              <div className={styles.competitors}>
                <span className={styles.main_competitor_text}>
                  Main
                  <span style={{ margin: "0 4px" }}>{"*"}</span>:
                </span>
                <div className={styles.main_competitor}>
                  <Dropdown
                    data={mainCompetitorDropdown}
                    added={filterList.mainCompetitor}
                    setAdded={(item) => {
                      HandleFilterListSelectedChange({
                        param: "mainCompetitor",
                        item,
                      });
                    }}
                    disabled={
                      filterList.destRegion.length < 1 &&
                      filterList.destCountry.length < 1 &&
                      filterList.destCityAirport.length < 1
                    }
                    singleSelected
                    border
                    getCarriers={getCarriers}
                    required={filterList.mainCompetitor.length < 1}
                  />
                </div>
                <span className={styles.other_competitor_text}>
                  Other
                  <span style={{ margin: "0 4px" }}>{"*"}</span>:
                </span>
                <div className={styles.other_competitor}>
                  <Dropdown
                    data={selectedCompetitorsDropdown}
                    added={filterList.selectedCompetitors}
                    setAdded={(item) => {
                      HandleFilterListSelectedChange({
                        param: "selectedCompetitors",
                        item,
                      });
                    }}
                    disabled={
                      filterList.destRegion.length < 1 &&
                      filterList.destCountry.length < 1 &&
                      filterList.destCityAirport.length < 1
                    }
                    border
                    getCarriers={getCarriers}
                    required={filterList.selectedCompetitors.length < 1}
                  />
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className={styles.body}>
          <Card
            variant="primary"
            title="Sales Channel"
            titleMargin="24px 16px 20px 16px"
            icon={images.hand_money_banknote}
            iconSize="sm"
            width={260}
            height={124}
            zIndex={1}
          >
            <div className={styles.filter_card}>
              <Dropdown
                data={salesChannelDropdown}
                added={filterList.salesChannel}
                setAdded={(item) => {
                  if (
                    filterList.destRegion.length > 0 ||
                    filterList.destCountry.length > 0 ||
                    (filterList.destCityAirport.length > 0 && item.length === 0)
                  ) {
                    HandleFilterListSelectedChange({
                      param: "salesChannel",
                      item: ["All"],
                    });
                    return;
                  }
                  HandleFilterListSelectedChange({
                    param: "salesChannel",
                    item,
                  });
                }}
                disabled={
                  filterList.destRegion.length < 1 &&
                  filterList.destCountry.length < 1 &&
                  filterList.destCityAirport.length < 1
                }
                getCarriers={getCarriers}
              />
            </div>
          </Card>
          <Card
            variant="primary"
            title="Cabin"
            titleMargin="24px 16px 20px 16px"
            icon={images.travel_plane_boarding_pass}
            iconSize="sm"
            width={260}
            height={124}
            zIndex={1}
          >
            <div className={styles.filter_buttons_wrapper}>
              {cabinDropdown.map((item) => {
                return (
                  <Button
                    key={item}
                    data={item}
                    added={filterList.cabin}
                    setAdded={(item) => {
                      HandleFilterListSelectedChange({
                        param: "cabin",
                        item,
                      });
                    }}
                    disabled={
                      filterList.destRegion.length < 1 &&
                      filterList.destCountry.length < 1 &&
                      filterList.destCityAirport.length < 1
                    }
                  >
                    {item}
                  </Button>
                );
              })}
            </div>
          </Card>
          <Card
            variant="primary"
            title="Passenger Type"
            titleMargin="24px 16px 20px 16px"
            icon={images.travel_plane_boarding_pass}
            iconSize="sm"
            width={260}
            height={124}
            zIndex={1}
          >
            <div className={styles.filter_buttons_wrapper}>
              {paxTypeDropdown.map((item) => {
                return (
                  <Button
                    key={item}
                    data={item}
                    added={filterList.paxType}
                    setAdded={(item) => {
                      HandleFilterListSelectedChange({
                        param: "paxType",
                        item,
                      });
                    }}
                    disabled={
                      filterList.destRegion.length < 1 &&
                      filterList.destCountry.length < 1 &&
                      filterList.destCityAirport.length < 1
                    }
                  >
                    {item}
                  </Button>
                );
              })}
            </div>
          </Card>
          <Card
            variant="primary"
            title="Direction"
            titleMargin="24px 16px 20px 16px"
            icon={images.travel_airplane}
            iconSize="sm"
            width={260}
            height={124}
          >
            <div className={styles.filter_card}>Directional</div>
          </Card>
        </div>
      </Card>
    </div>
  );
}
