import { IFilterContext } from "types/type";
import { QueryParameterStringBuilder } from "lib/query-parameter-string-builder";
import { RestClient } from "../../RestClient";

interface INetworkSchedulingBeyondPoints {
  filterList: IFilterContext;
  selected_year: string;
  dark_theme: boolean;
}

export class NetworkSchedulingBeyondPointsClient {
  private static _client: RestClient;

  static fetchNetworkSchedulingBeyondPoints({
    filterList,
    selected_year,
    dark_theme,
  }: INetworkSchedulingBeyondPoints) {
    const qp = QueryParameterStringBuilder.apply(filterList);
    return NetworkSchedulingBeyondPointsClient.getClient().getCall(
      `/api/msdv2/network-scheduling/beyond-points?${qp}&selected_year=${selected_year}&dark_theme=${dark_theme}`
    );
  }

  static getClient() {
    if (NetworkSchedulingBeyondPointsClient._client == null) {
      NetworkSchedulingBeyondPointsClient._client = new RestClient();
    }
    return NetworkSchedulingBeyondPointsClient._client;
  }
}
