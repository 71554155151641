import { useEffect, useState } from "react";
import Card from "components/common/Card/Card";
import Skeleton from "components/common/Skeleton";
import bg1 from "assets/temporary/bg1.webp";
import bg2 from "assets/temporary/bg2.webp";

interface ILowComp {
  filterIsOpen: boolean;
}
export default function ClevelBookingGrowth({ filterIsOpen }: ILowComp) {
  const [skeletonLoader, setSkeletonLoader] = useState(false);

  useEffect(() => {
    setSkeletonLoader(true);
    setTimeout(() => {
      setSkeletonLoader(false);
    }, 2000);
  }, []);

  return (
    <Card variant="secondary" title="Heatmap">
      {skeletonLoader ? (
        <Skeleton width={"100%"} height={616} />
      ) : (
        <>
          <div>
            <img
              src={bg1}
              alt=""
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
          <div>
            <img
              src={bg2}
              alt=""
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        </>
      )}
    </Card>
  );
}
