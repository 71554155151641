import MiniCard from "components/common/MiniCard/MiniCard";
import styles from "./styles.module.css";
import images from "constans/images";

import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import { useEffect, useState } from "react";
import Skeleton from "components/common/Skeleton";

const MiniKpi = () => {
  const [skeletonLoader, setSKeletonLoader] = useState(false);
  useEffect(() => {
    setSKeletonLoader(true);
    setTimeout(() => {
      setSKeletonLoader(false);
    }, 2000);
  }, []);
  return (
    <div className={styles.container}>
      {skeletonLoader ? (
        <>
          <Skeleton width={198} height={74} />
          <Skeleton width={198} height={74} />
          <Skeleton width={198} height={74} />
        </>
      ) : (
        <SortableComponent />
      )}
    </div>
  );
};

export default MiniKpi;
const SortableList = SortableContainer(({ items }) => {
  return (
    <div
      style={{ display: "flex", gap: 8, overflowX: "auto", padding: "12px 0" }}
    >
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} value={value} />
      ))}
    </div>
  );
});

const SortableItem = SortableElement(({ value }) => {
  return (
    <div style={{ cursor: "grab" }}>
      <MiniCard
        title={value?.title}
        total={value?.value}
        icon={value?.icon}
        iconBackgroundColor={value?.iconBackgroundColor}
        borderHoverColor={value?.borderHoverColor}
      />
    </div>
  );
});

const SortableComponent = () => {
  const [state, setState] = useState({
    items: [
      {
        title: "-",
        value: "-",
        icon: images.atom_icon_kpi,
        iconBackgroundColor: "#259eff20",
        borderHoverColor: "#259eff",
      },
      {
        title: "-",
        value: "-",
        icon: images.atom_icon_kpi_magenta,
        iconBackgroundColor: "#9c00ed20",
        borderHoverColor: "#9c00ed",
      },
      {
        title: "-",
        value: "-",
        icon: images.atom_icon_kpi_purple,
        iconBackgroundColor: "#5504d920",
        borderHoverColor: "#5504d9",
      },
      {
        title: "-",
        value: "-",
        icon: images.atom_icon_kpi_navy,
        iconBackgroundColor: "#144aff20",
        borderHoverColor: "#144aff",
      },
      {
        title: "-",
        value: "-",
        icon: images.atom_icon_kpi_yellow,
        iconBackgroundColor: "#da770620",
        borderHoverColor: "#da7706",
      },
      {
        title: "-",
        value: "-",
        icon: images.atom_icon_kpi_magenta,
        iconBackgroundColor: "#9c00ed20",
        borderHoverColor: "#9c00ed",
      },
      {
        title: "-",
        value: "-",
        icon: images.atom_icon_kpi_magenta,
        iconBackgroundColor: "#9c00ed20",
        borderHoverColor: "#9c00ed",
      },
      {
        title: "-",
        value: "-",
        icon: images.atom_icon_kpi_yellow,
        iconBackgroundColor: "#da770620",
        borderHoverColor: "#da7706",
      },
      {
        title: "-",
        value: "-",
        icon: images.atom_icon_kpi_navy,
        iconBackgroundColor: "#144aff20",
        borderHoverColor: "#144aff",
      },
    ],
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setState(({ items }) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }));
  };

  return (
    <SortableList
      helperClass={"SortableHelperWithOverride"}
      items={state.items}
      onSortEnd={onSortEnd}
      axis="xy"
      distance={1}
    />
  );
};
