import { useState, useEffect } from "react";
import Card from "components/common/Card/Card";
import TableComponent from "../../common/Table/Table";
import styles from "./styles.module.css";
import { FaresTableClient } from "lib/api/fa/fares/index";


export default function FareAnalyzer() {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [active, setActive] = useState(false);

  useEffect(() => {
    const params = {};
    FaresTableClient.fetchFaresTable(params)
      .then((res: any) => {
        setData([]);
        getColumns(res);
      })
      .catch((err) => console.log(err));
  }, [active]);

  const getColumns = (labels) => {
    const array: any = [];
    for (const [key, value] of Object.entries(labels)) {
      const obj = {
        name: value,
        selector: (row) => row[key],
        cell: (row) => <span className={"rdb-custom-cell"}>{row[key]}</span>,
        sortable: true,
        reorder: true,
      };
      array.push(obj);
    }
    setColumns([]);
  };
  return (
    <Card variant="secondary" height={700}>
      <div className={styles.card_header}>
        <span className={styles.title}>FA Table</span>
        <span className={styles.button_wrapper}>
          <span
            className={styles.button}
            data-active={active}
            onClick={() => setActive(true)}
          >
            Active
          </span>
          <span
            className={styles.button}
            data-active={!active}
            onClick={() => setActive(false)}
          >
            Deactive
          </span>
        </span>
      </div>
      <TableComponent
        data={data}
        columns={columns}
        selectableRows={false}
        noRowsPerPage={true}
        setSelected={() => {}}
        paginationPerPage={10}
        pagination={data?.length > 10}
        rowBorder
        nthChild
      />
    </Card>
  );
}
