import Card from "components/common/Card/Card";
import Plotly from "components/common/Charts/Plotly";
import styles from "./styles.module.css";
import Skeleton from "components/common/Skeleton";

interface IDaysToDepartureBreakdown {
  skeletonLoader: boolean;
  data: {
    data: never[];
    layout: {};
  };
  storyText: {
    bookings: {
      content: string;
      title: string;
    };
    class_rbd: {
      content: string;
      title: string;
    };
    days_to_departure: {
      content: string;
      title: string;
    };
    dow_revenue: {
      content: string;
      title: string;
    };
    fare_revenue: {
      content: string;
      title: string;
    };
    main_card: {
      content: string;
      title: string;
    };
    passenger_volume: {
      content: string;
      title: string;
    };
    ticket_type: {
      content: string;
      title: string;
    };
  };
}
export default function DaysToDepartureBreakdown({
  data,
  storyText,
  skeletonLoader,
}: IDaysToDepartureBreakdown) {
  return (
    <Card
      variant="secondary"
      title="Days to Departure Breakdown"
      width={"100%"}
      // cardInfo={{
      //   title: storyText?.days_to_departure?.title ?? null,
      //   description: storyText?.days_to_departure?.content ?? null,
      // }}
    >
      <div className={styles.container}>
        <div className={styles.chart_wrapper}>
          {skeletonLoader ? (
            <Skeleton width={"100%"} height={358} />
          ) : (
            <Card variant="primary" height={358}>
              <div className={styles.chart}>
                <p className={styles.chart_title}>Passengers</p>
                <Plotly
                  data={data?.data}
                  height={276}
                  layout={data?.layout}
                  margin={{ t: 20, b: 20, l: 30, r: 10, p: 4 }}
                />
                <p className={styles.chart_x_title}>Days to Departure</p>
              </div>
            </Card>
          )}
        </div>
      </div>
    </Card>
  );
}
