import { IFilterContext } from "types/type";
import { QueryParameterStringBuilder } from "lib/query-parameter-string-builder";
import { RestClient } from "../../RestClient";

interface INetworkSchedulingSchedulingComparisonDetails {
  filterList: IFilterContext;
  selected_yearmonth: string;
  dark_theme: boolean;
}

export class NetworkSchedulingSchedulingComparisonDetailsClient {
  private static _client: RestClient;

  static fetchNetworkSchedulingSchedulingComparisonDetails({
    filterList,
    selected_yearmonth,
    dark_theme,
  }: INetworkSchedulingSchedulingComparisonDetails) {
    const qp = QueryParameterStringBuilder.apply(filterList);
    return NetworkSchedulingSchedulingComparisonDetailsClient.getClient().getCall(
      `/api/msdv2/network-scheduling/scheduling-comparison-details?${qp}&selected_yearmonth=${selected_yearmonth}&dark_theme=${dark_theme}`
    );
  }

  static getClient() {
    if (NetworkSchedulingSchedulingComparisonDetailsClient._client == null) {
      NetworkSchedulingSchedulingComparisonDetailsClient._client =
        new RestClient();
    }
    return NetworkSchedulingSchedulingComparisonDetailsClient._client;
  }
}
