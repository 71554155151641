import Card from "components/common/Card/Card";
import Plotly from "components/common/Charts/Plotly";
import Skeleton from "components/common/Skeleton";

interface IPassengerVolume {
  skeletonLoader: boolean;
  data: {
    data: never[];
    layout: {};
  };
  storyText: {
    bookings: {
      content: string;
      title: string;
    };
    class_rbd: {
      content: string;
      title: string;
    };
    days_to_departure: {
      content: string;
      title: string;
    };
    dow_revenue: {
      content: string;
      title: string;
    };
    fare_revenue: {
      content: string;
      title: string;
    };
    main_card: {
      content: string;
      title: string;
    };
    passenger_volume: {
      content: string;
      title: string;
    };
    ticket_type: {
      content: string;
      title: string;
    };
  };
}
export default function PassengerVolume({
  data,
  storyText,
  skeletonLoader,
}: IPassengerVolume) {
  return (
    <Card
      variant="secondary"
      title="Passenger Volume"
      width={"40%"}
      // cardInfo={{
      //   title: storyText?.passenger_volume?.title ?? null,
      //   description: storyText?.passenger_volume?.content ?? null,
      // }}
    >
      {skeletonLoader ? (
        <Skeleton width={"100%"} height={364} />
      ) : (
        <Card variant="primary" height={364}>
          <Plotly
            data={data?.data}
            height={348}
            layout={data?.layout}
            margin={{ t: 20, b: 20, l: 20, r: 20, p: 4 }}
          />
        </Card>
      )}
    </Card>
  );
}
